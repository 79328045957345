import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'ui/Table';
import { Loader } from 'ui/Loader';
import { Error } from 'ui/Error';
import { useLoadMore } from 'hooks/useLoadMore';
import { useGetPartnerRevShareStatisticItemsQuery } from './hooks/useGetPartnerRevShareStatisticItemsQuery';
import { usePartnerRevShareStatisticItemsColumns } from './hooks/usePartnerRevShareStatisticItemsColumns';
import { useGeneratePartnerRevShareStatisticSummaryRow } from './hooks/useGeneratePartnerRevShareStatisticSummaryRow';

export const PartnerRevShareStatisticTable: FC = () => {
  const { t } = useTranslation('statistic');

  const {
    statisticItems,
    fetchMore,
    pageInfo,
    loading: itemsQueryLoading,
    error: itemsQueryError,
  } = useGetPartnerRevShareStatisticItemsQuery();

  const {
    summaryRowData,
    loading: totalQueryLoading,
    error: totalQueryError,
  } = useGeneratePartnerRevShareStatisticSummaryRow();

  const loadMore = useLoadMore(fetchMore, pageInfo);

  const columns = usePartnerRevShareStatisticItemsColumns();

  const loading = itemsQueryLoading || totalQueryLoading;
  const error = itemsQueryError || totalQueryError;

  if ((!statisticItems || !summaryRowData) && loading) {
    return <Loader />;
  }

  if (error || statisticItems === undefined) {
    return <Error error={error} />;
  }

  return (
    <Table
      columns={columns}
      data={statisticItems}
      summaryRow={summaryRowData}
      emptyText={t('emptyData')}
      isLoading={loading}
      hasMoreData={!!pageInfo?.hasNextPage}
      fetchMore={loadMore}
    />
  );
};
