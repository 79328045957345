import { FC } from 'react';
import { VerticalLayout } from 'ui/VerticalLayout';
import { useAuth } from 'components/auth';
import { LogoutButton } from 'components/LogoutButton';
import { LinksBlock } from 'components/LinksBlock';
import { useRedirectToApp } from './hooks/useRedirectToApp';
import { mapAuthStatusToBlock } from './helpers';

export const TwoFactor: FC = () => {
  const { user } = useAuth();

  useRedirectToApp();

  return (
    <VerticalLayout
      topBlock={<LogoutButton />}
      centralBlock={mapAuthStatusToBlock[user.authStatus]}
      bottomBlock={<LinksBlock />}
    />
  );
};
