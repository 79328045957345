import { useTranslation } from 'react-i18next';
import { useModalContext, openModal } from 'ui/Modal';
import { ChangeUserName } from '../components/ChangeUserName';
import { ChangePassword } from '../components/ChangePassword';
import { ResetTwoFactor } from '../components/ResetTwoFactor';
import { ChangeEmail } from '../components/ChangeEmail';

export const useAuthorizationModalActions = () => {
  const { dispatch } = useModalContext();

  const { t } = useTranslation('profile');

  const openModalChangeUserName = (): void => {
    dispatch(
      openModal({
        title: t('changeUserNameTitle'),
        content: <ChangeUserName />,
      })
    );
  };

  const openModalChangePassword = (): void => {
    dispatch(
      openModal({
        title: t('changePasswordTitle'),
        content: <ChangePassword />,
      })
    );
  };

  const openModalResetTwoFactor = (): void => {
    dispatch(
      openModal({
        title: t('resetTwoFactorTitle'),
        content: <ResetTwoFactor />,
      })
    );
  };

  const openModalChangeEmail = (): void => {
    dispatch(
      openModal({
        title: t('changeEmailTitle'),
        content: <ChangeEmail />,
      })
    );
  };

  return {
    openModalChangePassword,
    openModalChangeUserName,
    openModalResetTwoFactor,
    openModalChangeEmail,
  };
};
