import { FC } from 'react';
import { checkIsCountryCode } from 'utils/countries/checkIsCountryCode';
import {
  getCountryCodeByCurrencyCode,
  getFlagEmojiByCountryCode,
} from './helpers';
import styles from './FlagIcon.module.scss';

interface FlagIconProps {
  countryOrCurrencyCode: string;
}

export const FlagIcon: FC<FlagIconProps> = ({ countryOrCurrencyCode }) => {
  const countryCode = checkIsCountryCode(countryOrCurrencyCode)
    ? countryOrCurrencyCode
    : getCountryCodeByCurrencyCode(countryOrCurrencyCode);

  if (!countryCode) {
    return null;
  }

  const flagEmoji = getFlagEmojiByCountryCode(countryCode);

  return (
    <span data-testid="flag-emoji" className={styles.emoji}>
      {flagEmoji}
    </span>
  );
};
