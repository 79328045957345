import { useTranslation } from 'react-i18next';
import { ReactComponent as RewardIcon } from 'assets/icons/solid/Reward.svg';
import { CardWithIcon } from 'ui/CardWithIcon';
import { useNextRewardCurrencyModal } from './hooks/useNextRewardCurrencyModal';
import { useGetNextRewardInfoQuery } from './hooks/useGetNextRewardInfoQuery';

interface NextRewardCardProps {
  wrapperClassName?: string;
}

export const NextRewardCard = ({ wrapperClassName }: NextRewardCardProps) => {
  const { t } = useTranslation('payments');
  /*
   *  TODO: loading and error should be handled when design is done
   *  Issue https://eyeconweb.atlassian.net/browse/AMS-1265
   */
  const { nextRewardInfo, loading, error } = useGetNextRewardInfoQuery();
  const { openNextRewardCurrencyModal } = useNextRewardCurrencyModal({
    nextRewardInfo,
  });

  if (loading) {
    return 'Loading';
  }

  if (!nextRewardInfo || error) {
    return 'Error';
  }

  return (
    <CardWithIcon
      className={wrapperClassName}
      title={t('nextRewardTitle')}
      subTitle={t('nextRewardSubtitle', {
        currency: nextRewardInfo?.currency,
      })}
      onClick={openNextRewardCurrencyModal}
      icon={RewardIcon}
    />
  );
};
