import { MediaItemData } from '../../../../queries/fragments/generated/MediaItemData';

export const getMediaItemOwnerToShow = (
  mediaItemOwner: MediaItemData['owner']
): string | null => {
  if (mediaItemOwner.__typename === 'CompanyPartner') {
    return mediaItemOwner.username;
  }

  if (mediaItemOwner.__typename === 'Company') {
    return mediaItemOwner.name;
  }

  return null;
};
