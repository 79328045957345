import { useTranslation } from 'react-i18next';
import { toast } from 'ui/toast';
import { ApolloError } from '@apollo/client';
import { useAuth } from 'components/auth';
import { CreateCompanyMediaFormValues } from '../types';
import { useCreateCompanyReferralLinkMediaItem } from '../queries/generated/CreateCompanyReferralLinkMediaItem';

interface UseCreateCompanyReferralLinkMediaItemMutationParams {
  onCompleted: () => void;
}

export const useCreateCompanyReferralLinkMediaItemMutation = ({
  onCompleted,
}: UseCreateCompanyReferralLinkMediaItemMutationParams) => {
  const { user } = useAuth();
  const { t } = useTranslation('media');
  const [create, { loading }] = useCreateCompanyReferralLinkMediaItem();

  const handleCompleted = () => {
    toast.success(t('mediaCreated'));
    onCompleted?.();
  };

  const handleError = (error: ApolloError) => {
    toast.error({
      header: 'Error',
      text: error.message,
    });
  };

  const createReferralLinkMediaItem = ({
    mediaItemName,
    tariffId,
    mediaCampaignId,
    ownerId,
  }: CreateCompanyMediaFormValues) => {
    create({
      variables: {
        input: {
          companyId: user.id,
          mediaCampaignId,
          name: mediaItemName,
          companyPartnerId: ownerId || null,
          tariffId,
        },
      },
      onCompleted: handleCompleted,
      onError: handleError,
      // TODO: add after implement view company media items
      // refetchQueries: [ListAllOperations.Query.GetMediaItems],
    });
  };

  return { createReferralLinkMediaItem, loading };
};
