import { AppRoute } from 'const';
import { useNavigate } from 'react-router';
import { AuthStorageKey, Storage } from 'utils/storage';

export const useNavigateToSuccessPage = () => {
  const navigate = useNavigate();

  const navigateToSuccessPage = () => {
    if (Storage.authStorage.getValue(AuthStorageKey.ShowTwoFactorSuccess)) {
      navigate({ pathname: AppRoute.TwoFactorConnected });
    }
  };

  return { navigateToSuccessPage };
};
