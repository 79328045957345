import { useCallback } from 'react';
import { PageInfo } from 'types/generated/gql';

interface FetchMoreOptions {
  variables: {
    after?: string | null;
  };
}

interface FetchMore {
  (options: FetchMoreOptions): void;
}

export const useLoadMore = (fetchMore: FetchMore, pageInfo?: PageInfo) =>
  useCallback(() => {
    if (!pageInfo?.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        after: pageInfo.endCursor,
      },
    });
  }, [fetchMore, pageInfo?.endCursor, pageInfo?.hasNextPage]);
