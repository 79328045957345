import { useCallback } from 'react';
import { t } from 'i18next';
import { toast } from 'ui/toast';
import { useAuth } from 'components/auth';
import { useCreateApplicationToAddPartners } from '../queries/generated/CreateApplicationToAddPartners';
import { ApplicationToAddPartnersFormSubmitValues } from '../types';

interface UseCreateApplicationToAddPartnersParams {
  onCompleted: () => void;
}

export const useCreateApplicationToAddPartnersMutation = ({
  onCompleted,
}: UseCreateApplicationToAddPartnersParams) => {
  const { user } = useAuth();
  const [createApplicationToAddPartnersMutation, { loading }] =
    useCreateApplicationToAddPartners();

  const handleCompleted = useCallback(() => {
    toast.success({
      header: t('companyPartners:addPartnerModal.successToastHeader'),
      text: t('companyPartners:addPartnerModal.successToastMessage'),
    });
    onCompleted?.();
  }, [onCompleted]);

  const handleError = useCallback((errorMessage: string) => {
    toast.error({
      header: 'Error',
      text: errorMessage,
    });
  }, []);

  const createApplicationToAddPartners = useCallback(
    ({ companyPartners }: ApplicationToAddPartnersFormSubmitValues) => {
      if (!companyPartners.length) {
        return undefined;
      }

      return createApplicationToAddPartnersMutation({
        variables: {
          input: {
            applicationToAddPartnersItems: companyPartners,
            companyId: user.id,
          },
        },
        onError: (error) => handleError(error.message),
        onCompleted: handleCompleted,
      });
    },
    [
      createApplicationToAddPartnersMutation,
      handleCompleted,
      handleError,
      user.id,
    ]
  );

  return {
    createApplicationToAddPartners,
    loading,
  };
};
