import { useState } from 'react';
import { ReactComponent as EyeIcon } from 'assets/icons/line/Eye.svg';
import { ReactComponent as EyeClosedIcon } from 'assets/icons/line/EyeClosed.svg';
import { Input, InputProps } from '../Input/Input';
import { IconComponent } from '../../../../types';

type inputPasswordType = Extract<
  React.HTMLInputTypeAttribute,
  'password' | 'text'
>;

const mapInputTypeToIcon: Record<inputPasswordType, IconComponent> = {
  password: EyeIcon,
  text: EyeClosedIcon,
};

interface InputPasswordProps extends InputProps {
  className?: string;
}

export const InputPassword: React.FC<InputPasswordProps> = ({
  className,
  ...rest
}) => {
  const [currentInputType, setCurrentInputType] = useState<'password' | 'text'>(
    'password'
  );

  const CurrentSvg = mapInputTypeToIcon[currentInputType];

  const handleShowEyeIconClick: React.MouseEventHandler<HTMLSpanElement> = (
    e
  ) => {
    e.preventDefault();
    setCurrentInputType((prev) => (prev === 'password' ? 'text' : 'password'));
  };

  return (
    <Input
      {...rest}
      type={currentInputType}
      rightIconConfig={{
        icon: CurrentSvg,
        onClick: handleShowEyeIconClick,
      }}
    />
  );
};
