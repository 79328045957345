import { Country } from 'types';

const countryCodes = new Set(Object.values(Country));

export const checkIsCountryCode = (value?: string | null): value is Country => {
  if (!value) {
    return false;
  }

  return countryCodes.has(value as Country);
};
