import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
export type AvailableCurrencyAccountsData = {
  __typename: 'CurrencyAccount';
  amount?: number | null;
  currency: string;
  id: string;
  isCurrent: boolean;
};

export const AvailableCurrencyAccountsData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AvailableCurrencyAccountsData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CurrencyAccount' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'amount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'currency' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCurrent' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
