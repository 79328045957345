import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { ReactComponent as LogoutIcon } from 'assets/icons/line/Logout.svg';
import { useLogout } from 'hooks/useLogout';

export const LogoutButton: FC = () => {
  const { t } = useTranslation();
  const { logout, isLoading } = useLogout();

  const handleClick = () => {
    logout();
  };

  return (
    <Button
      data-testid="logout-button"
      onClick={handleClick}
      iconConfig={{ iconComponent: LogoutIcon, before: true }}
      theme={ButtonTheme.BlueLight}
      size={ButtonSize.Medium}
      isLoading={isLoading}
    >
      {t('logoutButton')}
    </Button>
  );
};
