import { FC } from 'react';
import classNames from 'classnames';
import { DropdownItem } from './types';
import { DropdownSize, DropdownPosition } from './const';
import { mapPositionToClassName, mapSizeToClassName } from './helpers';
import styles from './Dropdown.module.scss';

interface DropdownProps {
  items: Array<DropdownItem>;
  isOpen?: boolean;
  highlightOnHover?: boolean;
  size?: DropdownSize;
  position?: DropdownPosition;
  listContainerClassName?: string;
}

export const Dropdown: FC<DropdownProps> = ({
  items,
  highlightOnHover,
  isOpen,
  listContainerClassName,
  size = DropdownSize.Large,
  position = DropdownPosition.Left,
}) =>
  !isOpen ? null : (
    <div
      data-testid="dropdown"
      className={classNames(styles.dropdown, mapPositionToClassName[position])}
    >
      <ul className={classNames(styles.listContainer, listContainerClassName)}>
        {items.map((item) => {
          const { onClick, value, labelSub, leftElement, labelRight, label } =
            item;

          return (
            <li className={styles.listItem} key={label}>
              <button
                type="button"
                className={classNames(
                  styles.button,
                  highlightOnHover && styles.highlightOnHover,
                  mapSizeToClassName[size]
                )}
                onClick={() => {
                  onClick?.(value);
                }}
              >
                {leftElement}
                {labelSub ? (
                  <div
                    data-testid="with-label-container"
                    className={styles.withLabelContainer}
                  >
                    <span className={styles.label}>{label}</span>
                    <span className={styles.labelSub}>{labelSub}</span>
                  </div>
                ) : (
                  <span className={styles.label}>{label}</span>
                )}

                {labelRight && (
                  <span className={styles.labelRight}>{labelRight}</span>
                )}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
