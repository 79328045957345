import { Dispatch, FC, SetStateAction } from 'react';
import { PasswordResetSuccessful } from '../PasswordResetSuccessful';
import { PasswordResetFormWrapper } from '../PasswordResetFormWrapper';

interface PasswordResetBlockProps {
  setEmailSent: Dispatch<SetStateAction<boolean>>;
  hasEmailSent: boolean;
}

export const PasswordResetBlock: FC<PasswordResetBlockProps> = ({
  setEmailSent,
  hasEmailSent,
}) =>
  hasEmailSent ? (
    <PasswordResetSuccessful />
  ) : (
    <PasswordResetFormWrapper setEmailSent={setEmailSent} />
  );
