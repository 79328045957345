import { NextRewardCard } from './components/NextRewardCard';
import { WithdrawRewardCard } from './components/WithdrawRewardCard';
import { BalanceCard } from './components/BalanceCard';
import styles from './TopPanel.module.scss';

export const TopPanel = () => (
  <div className={styles.topPanelWrapper} data-testid="top-panel">
    <BalanceCard wrapperClassName={styles.balanceCardWrapper} />
    <div className={styles.rewardCardsWrapper}>
      <NextRewardCard wrapperClassName={styles.nextRewardCardWrapper} />
      <WithdrawRewardCard wrapperClassName={styles.withdrawRewardCardWrapper} />
    </div>
  </div>
);
