import { PostbackMacros } from 'types/generated/gql';
import { DropdownItem } from 'ui/Dropdown';
import { mapMacroToValue, mapMacroToDescription } from './mapHelpers';

export const getDropdownItemByMacros = (
  macros: Array<PostbackMacros>,
  onClick: (value?: string) => void
): Array<DropdownItem> =>
  macros.map((item) => {
    const value = mapMacroToValue[item];

    return {
      label: value,
      value,
      labelSub: mapMacroToDescription[item],
      onClick,
    };
  });
