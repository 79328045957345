import { FC, useCallback } from 'react';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MenuDotsIcon } from 'assets/icons/line/MenuDots.svg';
import { useModalContext, openModal as openModalAction } from 'ui/Modal';
import { Card } from 'ui/Card';
import { TariffBasicData } from '../../queries/generated/TariffBasicData';
import { TariffItem } from './components/TariffItem';
import { TariffCardBody } from './components/TariffCardBody';
import styles from './TariffCard.module.scss';

interface TariffCardProps {
  tariff: TariffBasicData;
}

export const TariffCard: FC<TariffCardProps> = ({ tariff }) => {
  const { t } = useTranslation('tariffs');
  const { dispatch } = useModalContext();

  const openModal = useCallback(() => {
    dispatch(
      openModalAction({
        title: t('about'),
        content: <TariffItem tariffId={tariff.id} />,
      })
    );
  }, [dispatch, t, tariff]);

  return (
    <Card
      className={styles.card}
      title={tariff.name}
      headerRightBlock={
        <Button
          theme={ButtonTheme.Grey}
          size={ButtonSize.Small}
          onClick={openModal}
          iconConfig={{
            iconComponent: MenuDotsIcon,
          }}
        />
      }
    >
      <TariffCardBody className={styles.cardBody} tariff={tariff} />
      <Button theme={ButtonTheme.Grey} size={ButtonSize.Small}>
        {t('media')}
      </Button>
    </Card>
  );
};
