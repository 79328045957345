import { FormikErrors } from 'formik';
import { t } from 'i18n';
import { checkEmailValidity } from 'utils/form/checkEmailValidity';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import { getPasswordError } from 'utils/form/getPasswordError';
import { checkIsValidUrl } from 'utils/checkIsValidUrl';
import { RegistrationValues } from './types';
import { FieldName } from './const';

export const validateCompanyRegistration = (
  values: RegistrationValues
): FormikErrors<RegistrationValues> => {
  const errors: FormikErrors<RegistrationValues> = {};

  if (values.website && !checkIsValidUrl(values.website)) {
    errors.website = t('errors.incorrectFormat');
  }

  if (!checkEmailValidity(values.email)) {
    errors.email = t('errors.incorrectFormat');
  }

  const passwordError = getPasswordError(values.createPassword);

  if (passwordError) {
    errors.createPassword = passwordError;
  }

  if (values.createPassword !== values.confirmPassword) {
    errors.confirmPassword = t('errors.passwordMismatch');
  }

  const requiredErrors = getRequiredErrors(values, {
    [FieldName.CreatePassword]: true,
    [FieldName.ConfirmPassword]: true,
    [FieldName.ContactType]: true,
    [FieldName.ContactText]: true,
    [FieldName.TrafficType]: false,
    [FieldName.Email]: true,
    [FieldName.CurrencySelect]: true,
    [FieldName.Name]: true,
  });

  return {
    ...errors,
    ...requiredErrors,
  };
};
