import { Currency, UserTypeName } from 'types';
import { useSwitchPartnerNextRewardCurrencyMutation } from './useSwitchPartnerNextRewardCurrencyMutation';
import { useSwitchCompanyNextRewardCurrencyMutation } from './useSwitchCompanyNextRewardCurrencyMutation';

interface UseSwitchNextRewardCurrencyParams {
  userTypeName?: 'Partner' | 'Company';
  onCompleted: (currency: Currency) => void;
}

export const useSwitchNextRewardCurrency = ({
  userTypeName,
  onCompleted,
}: UseSwitchNextRewardCurrencyParams) => {
  const useSwitchPartnerNextRewardCurrencyByUserType =
    userTypeName === UserTypeName.Partner
      ? useSwitchPartnerNextRewardCurrencyMutation
      : useSwitchCompanyNextRewardCurrencyMutation;

  return useSwitchPartnerNextRewardCurrencyByUserType(onCompleted);
};
