import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { UserTypeName } from 'types';
import { useGetPartnerCpaStatisticItems } from '../queries/generated/GetPartnerCpaStatisticItems';
import { PartnerCpaStatisticItemData } from '../queries/fragments/generated/PartnerCpaStatisticItemData';
import { STATISTIC_ITEMS_PER_PAGE } from '../const';

export const useGetPartnerCpaStatisticItemsQuery = () => {
  const { user } = useAuth();

  const { data, fetchMore, loading, error } = useGetPartnerCpaStatisticItems({
    variables: {
      first: STATISTIC_ITEMS_PER_PAGE,
      userId: user.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const statisticItemsConnection =
    data?.user.__typename === UserTypeName.Partner
      ? data.user.cpaStatistic.statisticItems
      : undefined;

  const statisticItems: Array<PartnerCpaStatisticItemData> | undefined =
    useMemo(
      () => statisticItemsConnection?.edges.map((item) => item.node),
      [statisticItemsConnection?.edges]
    );

  const pageInfo = statisticItemsConnection?.pageInfo;

  return { statisticItems, fetchMore, pageInfo, loading, error };
};
