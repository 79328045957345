import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo } from 'components/Logo';
import { SPEC_HEADER_LARGE, AppRoute } from 'const';
import { TextWithLink } from 'ui/TextWithLink';
import { VerticalLayout } from 'ui/VerticalLayout';
import { LoginForm } from './components/LoginForm';
import styles from './Login.module.scss';

export const Login: FC = () => {
  const { t } = useTranslation('auth');
  const [hasError, setHasError] = useState(false);

  return (
    <VerticalLayout
      topBlock={<Logo className={styles.logo} />}
      centralBlock={
        <>
          <div className={styles.textBlock}>
            <h1 className={SPEC_HEADER_LARGE}>{t('login.header')}</h1>
            <p>{t('login.description')}</p>
          </div>

          <LoginForm setHasError={setHasError} />
        </>
      }
      bottomBlock={
        <TextWithLink
          text={t('login.problem')}
          linkText={t('login.support')}
          important={hasError}
          to={AppRoute.Support}
        />
      }
    />
  );
};
