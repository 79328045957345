import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext, openModal } from 'ui/Modal';
import { ConfirmModal, ConfirmModalContextProvider } from 'ui/ConfirmModal';
import { DateFormat, formatDate } from 'utils/formatDate';
import { NextRewardInfo } from 'types/generated/gql';
import { NextRewardCurrencyModal } from '../components/NextRewardCurrencyModal';

interface UseNextRewardCurrencyModalParams {
  nextRewardInfo?: NextRewardInfo;
}

export const useNextRewardCurrencyModal = ({
  nextRewardInfo,
}: UseNextRewardCurrencyModalParams) => {
  const { t } = useTranslation(['common', 'payments']);
  const { dispatch } = useModalContext();

  const openNextRewardCurrencyModal = useCallback(() => {
    if (!nextRewardInfo) {
      return;
    }

    const periodStart = formatDate(
      new Date(nextRewardInfo?.period?.start),
      DateFormat.Day
    );
    const periodEnd = formatDate(
      new Date(nextRewardInfo?.period?.end),
      DateFormat.DayMonth
    );

    dispatch(
      openModal({
        title: t('payments:nextRewardCurrencyModal.modalTitle'),
        subTitle:
          !periodStart || !periodEnd
            ? null
            : t('payments:nextRewardCurrencyModal.modalSubtitle', {
                monthDayStart: periodStart,
                monthDayEnd: periodEnd,
              }),
        content: (
          <ConfirmModalContextProvider>
            <NextRewardCurrencyModal nextRewardInfo={nextRewardInfo} />
            <ConfirmModal
              content={{
                cancelButton: t('pageCloseConfirmModalContent.cancelButton'),
                confirmButton: t('pageCloseConfirmModalContent.confirmButton'),
                text: t('pageCloseConfirmModalContent.text'),
                title: t('pageCloseConfirmModalContent.title'),
              }}
            />
          </ConfirmModalContextProvider>
        ),
      })
    );
  }, [dispatch, t, nextRewardInfo]);

  return {
    openNextRewardCurrencyModal,
  };
};
