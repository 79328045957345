import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonTheme } from 'ui/Button';
import styles from './ChangePassword.module.scss';

export const ChangePassword: FC = () => {
  const { t } = useTranslation('profile');

  return (
    <div>
      <div className={styles.text}>{t('changePasswordText')}</div>
      <Button theme={ButtonTheme.Grey}>{t('support')}</Button>
    </div>
  );
};
