import {
  ChangeEventHandler,
  ClipboardEventHandler,
  FocusEventHandler,
  KeyboardEventHandler,
  RefObject,
  useEffect,
} from 'react';
import { useField } from 'formik';
import { blockInvalidChars } from '../../../utils/form/blockInvalidChars';

type HTMLInputTextareaElement = HTMLInputElement | HTMLTextAreaElement;

interface EventPropsForField {
  onChange: ChangeEventHandler<HTMLInputTextareaElement>;
  onPaste: ClipboardEventHandler<HTMLInputTextareaElement>;
  onKeyDown: KeyboardEventHandler<HTMLInputTextareaElement>;
  onBlur: FocusEventHandler<HTMLInputTextareaElement>;
}

interface UseInputManagementProps {
  name: string;
  inputRef: RefObject<HTMLInputTextareaElement> | null;
  onChange?: (value: string) => void;
  invalidChars?: Array<string>;
  acceptedCharsRegExp?: RegExp;
  maxLength?: number;
  autoFocus?: boolean;
}
export const useInputManagement = ({
  name,
  inputRef,
  onChange,
  invalidChars,
  acceptedCharsRegExp,
  maxLength,
  autoFocus,
}: UseInputManagementProps) => {
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  const handleChange: ChangeEventHandler<HTMLInputTextareaElement> = (
    event
  ) => {
    const { value } = event.target;

    if (maxLength && value.length > maxLength) {
      return;
    }

    field.onChange(event);
    onChange?.(value);
  };

  const handlePast: ClipboardEventHandler<HTMLInputTextareaElement> = (
    event
  ) => {
    if (!acceptedCharsRegExp) {
      return;
    }

    const { clipboardData } = event;

    const globalRegex = new RegExp(acceptedCharsRegExp.source, 'g');
    const pastedData = clipboardData.getData('Text');

    if (pastedData.match(globalRegex)?.length !== pastedData.length) {
      event.preventDefault();
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputTextareaElement> = (
    event
  ) => {
    if (acceptedCharsRegExp && !acceptedCharsRegExp.test(event.key)) {
      event.preventDefault();
    }

    if (!invalidChars?.length) {
      return;
    }

    blockInvalidChars(event, invalidChars);
  };

  const handleBlur: FocusEventHandler<HTMLInputTextareaElement> = (event) => {
    field.onBlur(event);
  };

  const eventProps: EventPropsForField = {
    onChange: handleChange,
    onKeyDown: handleKeyDown,
    onBlur: handleBlur,
    onPaste: handlePast,
  };

  useEffect(() => {
    if (autoFocus) {
      inputRef?.current?.focus();
    }
  }, [autoFocus, inputRef]);

  return {
    eventProps,
    field,
    touched,
    error,
  };
};
