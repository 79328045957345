import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import { AuthStatuses, useAuth } from 'components/auth';
import { AppRoute } from 'const';

export const PrivateRouteWrapper: FC = () => {
  const { user } = useAuth();
  const location = useLocation();

  const shouldNavigateToLogin = user.authStatus !== AuthStatuses.Authenticated;

  if (shouldNavigateToLogin) {
    return <Navigate to={AppRoute.Login} state={{ from: location }} replace />;
  }

  return <Outlet />;
};
