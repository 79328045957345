import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetNextRewardInfoVariables = SchemaTypes.Exact<{
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetNextRewardInfo = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        nextRewardInfo: {
          __typename: 'NextRewardInfo';
          currency: string;
          period: { __typename: 'DateRange'; end: string; start: string };
        };
      }
    | {
        __typename: 'Partner';
        id: string;
        nextRewardInfo: {
          __typename: 'NextRewardInfo';
          currency: string;
          period: { __typename: 'DateRange'; end: string; start: string };
        };
      };
};

export const GetNextRewardInfoDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetNextRewardInfo' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextRewardInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currency' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'period' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextRewardInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currency' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'period' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetNextRewardInfo__
 *
 * To run a query within a React component, call `useGetNextRewardInfo` and pass it any options that fit your needs.
 * When your component renders, `useGetNextRewardInfo` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNextRewardInfo({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetNextRewardInfo(
  baseOptions: Apollo.QueryHookOptions<
    GetNextRewardInfo,
    GetNextRewardInfoVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNextRewardInfo, GetNextRewardInfoVariables>(
    GetNextRewardInfoDocument,
    options
  );
}
export function useGetNextRewardInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNextRewardInfo,
    GetNextRewardInfoVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNextRewardInfo, GetNextRewardInfoVariables>(
    GetNextRewardInfoDocument,
    options
  );
}
export type GetNextRewardInfoHookResult = ReturnType<
  typeof useGetNextRewardInfo
>;
export type GetNextRewardInfoLazyQueryHookResult = ReturnType<
  typeof useGetNextRewardInfoLazyQuery
>;
export type GetNextRewardInfoQueryResult = Apollo.QueryResult<
  GetNextRewardInfo,
  GetNextRewardInfoVariables
>;
