import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'ui/toast';
import { checkIsCurrency } from 'utils/currency/checkIsCurrency';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';
import { Currency } from 'types';

interface UseSwitchNextRewardCurrencyRequestHandlersParams {
  onCompleted: (currency: Currency) => void;
}

export const useSwitchNextRewardCurrencyRequestHandlers = ({
  onCompleted,
}: UseSwitchNextRewardCurrencyRequestHandlersParams) => {
  const { t } = useTranslation('payments');

  const handleCompleted = useCallback(
    (currency: string) => {
      if (checkIsCurrency(currency)) {
        onCompleted?.(currency);

        toast.success({
          header: t('nextRewardCurrencyModal.alertSuccessMessageHeader'),
          text: `${t('nextRewardCurrencyModal.alertSuccessMessageText')} ${getCurrencyDisplayName(currency)}`,
        });

        return;
      }

      throw new Error('Unknown currency type');
    },
    [onCompleted, t]
  );

  const handleError = useCallback((errorMessage: string) => {
    toast.error({
      header: 'Error',
      text: errorMessage,
    });
  }, []);

  return {
    handleCompleted,
    handleError,
  };
};
