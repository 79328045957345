import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { UserTypeName } from 'types';
import { useGetPartnerRevShareStatisticItems } from '../queries/generated/GetPartnerRevShareStatisticItems';
import { PartnerRevShareStatisticItemData } from '../queries/fragments/generated/PartnerRevShareStatisticItemData';
import { STATISTIC_ITEMS_PER_PAGE } from '../const';

export const useGetPartnerRevShareStatisticItemsQuery = () => {
  const { user } = useAuth();

  const { data, fetchMore, loading, error } =
    useGetPartnerRevShareStatisticItems({
      variables: {
        first: STATISTIC_ITEMS_PER_PAGE,
        userId: user.id,
      },
      notifyOnNetworkStatusChange: true,
    });

  const statisticItemsConnection =
    data?.user.__typename === UserTypeName.Partner
      ? data.user.revShareStatistic.statisticItems
      : undefined;

  const statisticItems: Array<PartnerRevShareStatisticItemData> | undefined =
    useMemo(
      () => statisticItemsConnection?.edges.map((item) => item.node),
      [statisticItemsConnection?.edges]
    );

  const pageInfo = statisticItemsConnection?.pageInfo;

  return { statisticItems, fetchMore, pageInfo, loading, error };
};
