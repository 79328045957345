import { Currency } from 'types/common';
import { capitalize } from '../capitalize';

export const getCurrencyDisplayName = (
  currencyCode: Currency,
  locale = 'ru-RU',
  shouldCapitalize = true
): string => {
  const currencyNames = new Intl.DisplayNames([locale], { type: 'currency' });

  let currencyName: string;

  try {
    currencyName = currencyNames.of(currencyCode) || currencyCode;
  } catch {
    currencyName = currencyCode;
  }

  return shouldCapitalize ? capitalize(currencyName) : currencyName;
};
