import { FC } from 'react';
import { Icon, IconSize } from 'ui/Icon';
import classnames from 'classnames';
import {
  TariffBasicData_CpaTariff,
  TariffBasicData_RevShareTariff,
} from 'pages/Tariffs/queries/generated/TariffBasicData';
import {
  mapTariffTypeToClass,
  mapTariffTypeToIcon,
  mapTariffTypeToText,
} from './const';
import styles from './PaymentModel.module.scss';

interface PaymentModelProps {
  tariffTypeName:
    | TariffBasicData_CpaTariff['__typename']
    | TariffBasicData_RevShareTariff['__typename'];
  iconSize?: IconSize;
}

export const PaymentModel: FC<PaymentModelProps> = ({
  tariffTypeName,
  iconSize = IconSize.Medium,
}) => {
  const tariffTypeClassName = mapTariffTypeToClass[tariffTypeName];
  const icon = mapTariffTypeToIcon[tariffTypeName];
  const tariffName = mapTariffTypeToText[tariffTypeName];

  return (
    <div className={classnames(styles.paymentModel, tariffTypeClassName)}>
      <Icon
        iconComponent={icon}
        hasDefaultColor={false}
        containerClassName={styles.iconContainer}
        size={iconSize}
      />
      {tariffName}
    </div>
  );
};
