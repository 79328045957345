import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PaymentsIcon } from 'assets/icons/colors/WadOfMoney.svg';
import { TopBar } from 'components/TopBar';
import { TopPanel } from './components/TopPanel';

export const Payments: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <TopBar title={t('pageTitles.payments')} icon={PaymentsIcon} />
      <TopPanel />
    </>
  );
};
