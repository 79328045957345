import { FC } from 'react';
import { Select } from 'ui/formItems';
import { useTranslation } from 'react-i18next';
import { currencySelectOptions } from 'utils/currency/currencySelectOptions';

interface CurrencySelectProps {
  name: string;
}

export const CurrencySelect: FC<CurrencySelectProps> = ({ name }) => {
  const { t } = useTranslation('auth');

  return (
    <Select
      label={t('registration.formCommonFields.currency')}
      formValidationProps={{ errorTooltipPlacement: 'right' }}
      name={name}
      options={currencySelectOptions}
    />
  );
};
