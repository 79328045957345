import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AppRoute, SPEC_HEADER_LARGE } from 'const';
import { VerticalLayout } from 'ui/VerticalLayout';
import { TextWithLink } from 'ui/TextWithLink';
import { ReturnButton } from 'components/ReturnButton';
import { ChooseRegistrationType } from './components/ChooseRegistrationType';
import styles from './Registration.module.scss';

export const Registration: FC = () => {
  const { t } = useTranslation(['common', 'auth']);

  return (
    <VerticalLayout
      topBlock={<ReturnButton />}
      centralBlock={
        <>
          <div className={styles.textBlock}>
            <h1 className={classNames(styles.header, SPEC_HEADER_LARGE)}>
              {t('auth:registration.header')}
            </h1>
          </div>

          <ChooseRegistrationType />
          <TextWithLink
            text={t('auth:registration.hasAccount')}
            linkText={t('enterButton')}
            to={AppRoute.Login}
          />
        </>
      }
    />
  );
};
