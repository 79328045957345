import { useAuth } from 'components/auth';
import { UserTypeName } from 'types';
import { useGetPartnerRevShareStatisticTotal } from '../queries/generated/GetPartnerRevShareStatisticTotal';

export const useGetRevShareStatisticTotalQuery = () => {
  const { user } = useAuth();

  const { data, loading, error } = useGetPartnerRevShareStatisticTotal({
    variables: {
      userId: user.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const totalStatistic =
    data?.user.__typename === UserTypeName.Partner
      ? data.user.revShareStatistic.total
      : undefined;

  return { totalStatistic, loading, error };
};
