import { FC } from 'react';
import { Button } from 'ui/Button';
import { useNavigate } from 'react-router';
import { SPEC_HEADER_LARGE, AppRoute } from 'const';
import { useTranslation } from 'react-i18next';
import styles from './PasswordResetSuccessful.module.scss';

export const PasswordResetSuccessful: FC = () => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(AppRoute.Login);
  };

  return (
    <div className={styles.container}>
      <h1 className={SPEC_HEADER_LARGE}>{t('passwordReset.successHeader')}</h1>
      <p className={styles.description}>{t('passwordReset.checkEmail')}</p>
      <Button isFullWidth onClick={handleClick}>
        {t('passwordReset.loginPageButton')}
      </Button>
    </div>
  );
};
