import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { AFFILIATE_EMAIL, SPEC_HEADER_LARGE } from 'const';
import { TextLink } from 'ui/TextLink';
import notFoundImage from 'assets/images/404.svg';
import styles from './NotFound.module.scss';

export const NotFound: FC = () => {
  const { t } = useTranslation('notFound');

  return (
    <>
      <h1 className={classNames(SPEC_HEADER_LARGE, styles.header)}>
        {t('header')}
      </h1>
      <p className={styles.text}>
        {t('topDescriptionOne')}
        <br />
        {t('topDescriptionTwo')}
      </p>
      <img
        src={notFoundImage}
        alt={t('notFoundImageAlt')}
        className={styles.errorIcon}
      />
      <p className={styles.text}>
        {t('bottomDescription')}
        <br />
        <TextLink to={`mailto:${AFFILIATE_EMAIL}`}>{AFFILIATE_EMAIL}</TextLink>
      </p>
    </>
  );
};
