import { useAuth } from 'components/auth';
import { useGetMediaItem } from '../../../queries/generated/GetMediaItem';

interface UseGetMediaItemQuery {
  mediaItemId: string;
}

export const useGetMediaItemQuery = ({ mediaItemId }: UseGetMediaItemQuery) => {
  const { user } = useAuth();
  const { data, loading, error } = useGetMediaItem({
    variables: {
      userId: user.id,
      mediaItemId,
    },
  });

  return {
    mediaItem: data?.user.mediaItem,
    loading,
    error,
  };
};
