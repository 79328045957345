import { FormikErrors } from 'formik';
import i18n from 'i18n';

/**
 * Validates required fields and returns error messages.
 *
 * @param {T} allValues - The form values to be validated.
 * @param {Partial<Record<keyof T, boolean>>} required - An object specifying which fields are required.
 *
 * @returns {FormikErrors<T>} - An object containing error messages for each required field that is empty.
 *
 * @throws Will throw an error if the provided `allValues` is not an object.
 *
 * @example
 * const errors = getRequiredErrors({ name: '', age: '25' }, { name: true });
 * console.log(errors); // { name: 'errors.requiredField' }
 */
export const getRequiredErrors = <T extends object>(
  allValues: T,
  required: Partial<Record<keyof T, boolean>>
): FormikErrors<T> => {
  const errors: Partial<Record<keyof T, string | undefined>> = {};

  Object.keys(allValues).forEach((key) => {
    const typedKey = key as keyof T;
    const value = allValues[typedKey];

    // Using 'as' for array check due to TypeScript limitations.
    // In TypeScript 5.2+, this can be omitted.
    const isValueEmpty = Array.isArray(value)
      ? (value as Array<unknown>).length === 0
      : value === undefined || value === '';

    if (isValueEmpty && required[typedKey]) {
      errors[typedKey] = i18n.t('errors.requiredField');
    }
  });

  return errors as FormikErrors<T>;
};
