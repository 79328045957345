import i18n from 'i18n';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { DateFormat, formatDate } from 'utils/formatDate';
import { CompanyPartnersData } from '../queries/fragments/generated/CompanyPartnersData';

const NOTE_COLUMN_MAX_WIDTH = 719;

const { t } = i18n;

export const useCompanyPartnersTableColumns = (): Array<
  ColumnDef<CompanyPartnersData>
> => {
  const columnHelper = createColumnHelper<CompanyPartnersData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<CompanyPartnersData, any>> = [
    columnHelper.accessor('id', {
      header: t('companyPartners:usernameAndId'),
      cell: ({ row }) => (
        <MultilineCellWithIcon
          title={row.original.username}
          subTitle={`#${row.original.id}`}
        />
      ),
      enablePinning: true,
    }),
    columnHelper.accessor('mediaItemsCount', {
      header: t('companyPartners:mediaCount'),
      cell: ({ row }) => row.original.mediaItemsCount,
    }),
    columnHelper.accessor('note', {
      header: t('companyPartners:note'),
      cell: ({ row }) => row.original.note,
      maxSize: NOTE_COLUMN_MAX_WIDTH,
    }),
    columnHelper.accessor('createdAt', {
      header: t('companyPartners:createdAt'),
      cell: ({ row }) =>
        formatDate(
          new Date(row.original.createdAt),
          DateFormat.FullDateWithoutHours
        ),
    }),
  ];

  return columns;
};
