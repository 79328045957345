import { DropdownItem } from 'ui/Dropdown';
import { getCurrencySymbol } from 'utils/currency/getCurrencySymbol';
import { checkIsCurrency } from 'utils/currency/checkIsCurrency';
import { CurrencyAccount } from 'types/generated/gql';
import { FlagIcon } from 'ui/FlagIcon';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';

export const getDropdownItemsByCurrencies = (
  availableCurrencyAccounts: Array<CurrencyAccount | null>,
  onClick: (value?: string) => void
): Array<DropdownItem> => {
  if (!availableCurrencyAccounts.length) {
    return [];
  }

  const filteredCurrencyAccounts = availableCurrencyAccounts.filter(
    (account): account is CurrencyAccount => account !== null
  );

  return filteredCurrencyAccounts.map(({ currency }) => ({
    label: `${checkIsCurrency(currency) && getCurrencyDisplayName(currency)}`,
    labelRight: `${currency} — ${
      checkIsCurrency(currency) &&
      getCurrencySymbol({
        currency,
      })
    }`,
    leftElement: <FlagIcon countryOrCurrencyCode={currency} />,
    value: currency,
    onClick,
  }));
};
