import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextLink } from 'ui/TextLink/TextLink';
import { AppRoute } from 'const';
import styles from './LinksBlock.module.scss';

export const LinksBlock: FC = () => {
  const { t } = useTranslation(['common', 'auth']);

  return (
    <div data-testid="links-block">
      <TextLink className={styles.link} to="#">
        {t('termsAndConditions')}
      </TextLink>
      <TextLink className={styles.link} to="#">
        {t('faq')}
      </TextLink>
      <TextLink className={styles.link} to={AppRoute.Support}>
        {t('auth:application.support')}
      </TextLink>
    </div>
  );
};
