import { FlagIcon } from 'ui/FlagIcon';
import { SelectOption } from '../../ui/formItems';
import { Currency } from '../../types';
import { getCurrencyDisplayName } from './getCurrencyDisplayName';
import { getCurrencyNameWithSymbol } from './getCurrencyNameWithSymbol';

export const currencySelectOptions: Array<SelectOption> = Object.values(
  Currency
).map((currency) => ({
  label: getCurrencyDisplayName(currency),
  value: currency,
  leftElement: <FlagIcon countryOrCurrencyCode={currency} />,
  labelRight: getCurrencyNameWithSymbol(currency),
}));
