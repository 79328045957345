import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import classNames from 'classnames';
import { Icon } from 'ui/Icon';
import { IconComponent } from 'types';
import styles from './ListButton.module.scss';

interface ListButtonProps {
  icon: IconComponent;
  title: string;
  subTitle: string;
  rightBlock?: ReactNode;
}

type PropsDependsOnElement =
  | (ButtonHTMLAttributes<HTMLButtonElement> & {
      as: 'button';
    })
  | (LinkProps & {
      as?: 'a';
    });

export const ListButton: FC<PropsDependsOnElement & ListButtonProps> = ({
  icon,
  title,
  subTitle,
  rightBlock,
  className,
  ...restProps
}) => {
  const content = (
    <>
      <div className={styles.iconContainer}>
        <Icon iconComponent={icon} />
      </div>
      <div className={styles.content}>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.subTitle}>{subTitle}</div>
        </div>
        {rightBlock}
      </div>
    </>
  );

  if (restProps.as === 'button') {
    const isNotClickable = !restProps.onClick;

    return (
      <button
        className={classNames(
          isNotClickable && styles.notClickable,
          styles.container,
          className
        )}
        type="button"
        {...restProps}
      >
        {content}
      </button>
    );
  }

  return (
    <Link
      className={classNames(styles.container, className)}
      target="_blank"
      {...restProps}
    >
      {content}
    </Link>
  );
};
