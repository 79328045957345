import { useAuth } from 'components/auth';
import { UserTypeName } from 'types';
import { useGetAvailableCurrencyAccounts } from '../queries/generated/GetAvailableCurrencyAccounts';

export const useGetAvailableCurrencyAccountsQuery = () => {
  const { user } = useAuth();

  const { data, error, loading } = useGetAvailableCurrencyAccounts({
    variables: {
      userId: user.id,
    },
  });

  const availableCurrencyAccounts =
    data?.user.__typename === UserTypeName.Partner
      ? data.user.availableCurrencyAccounts
      : undefined;

  return {
    availableCurrencyAccounts,
    error,
    loading,
  };
};
