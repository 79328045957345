import { FC } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import {
  FormBlockMarginSize,
  FormBlockWrapper,
  Input,
  InputPassword,
  SelectAndInput,
  Textarea,
} from 'ui/formItems';
import { useTranslation } from 'react-i18next';
import { Button } from 'ui/Button';
import { contactTypeOption } from 'helpers';
import { FieldName, mapFiledNameToMaxLength } from './const';
import { RegistrationValues } from './types';
import { CurrencySelect } from '../../../../components/CurrencySelect';
import { validateCompanyRegistration } from './validation';
import { useRegisterCompanyAndLogIn } from './hooks/useRegisterCompanyAndLogIn';

const initialValues: RegistrationValues = {
  [FieldName.ContactType]: undefined,
  [FieldName.ContactText]: '',
  [FieldName.CurrencySelect]: '',
  [FieldName.Name]: '',
  [FieldName.Website]: undefined,
  [FieldName.TrafficType]: undefined,
  [FieldName.Email]: '',
  [FieldName.CreatePassword]: '',
  [FieldName.ConfirmPassword]: '',
};

export const RegistrationCompanyForm: FC = () => {
  const { t } = useTranslation('auth');
  const { isLoading, registerAndLogin } = useRegisterCompanyAndLogIn();

  /**
   * await is required here because as a result of executing registerAndLogin,
   * errors might be set in the email and name fields of the form, and the logic for displaying the Tooltip
   * on fields with errors works correctly only if we wait for the result of the request execution in handleSubmit.
   */
  const handleSubmit = async (
    values: RegistrationValues,
    actions: FormikHelpers<RegistrationValues>
  ) => {
    await registerAndLogin(values, actions);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validateCompanyRegistration}
    >
      {({ values, errors }) => (
        <Form data-testid="registration-company-form" noValidate>
          <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Large}>
            <SelectAndInput
              selectProps={{
                name: FieldName.ContactType,
                label: t('registration.formCommonFields.contactType'),
                options: contactTypeOption,
              }}
              inputProps={{
                name: FieldName.ContactText,
                label: t('registration.formCommonFields.contactText'),
                disabled: !values[FieldName.ContactType],
                maxLength: mapFiledNameToMaxLength[FieldName.ContactText],
              }}
              isError={Boolean(errors.contactText)}
            />
            <Input
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              name={FieldName.Name}
              label={t('registration.company.form.companyName')}
              maxLength={mapFiledNameToMaxLength[FieldName.Name]}
            />
            <Input
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              name={FieldName.Website}
              label={t('registration.company.form.companyWebsite')}
              maxLength={mapFiledNameToMaxLength[FieldName.Website]}
            />
            <Textarea
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              name={FieldName.TrafficType}
              label={t('registration.formCommonFields.trafficSource')}
              maxLength={mapFiledNameToMaxLength[FieldName.TrafficType]}
            />
            <CurrencySelect name={FieldName.CurrencySelect} />
            <Input
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              type="email"
              name={FieldName.Email}
              label={t('registration.formCommonFields.email')}
              maxLength={mapFiledNameToMaxLength[FieldName.Email]}
            />
            <InputPassword
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              name={FieldName.CreatePassword}
              label={t('registration.formCommonFields.createPassword')}
            />
            <InputPassword
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              name={FieldName.ConfirmPassword}
              label={t('registration.formCommonFields.confirmPassword')}
            />
          </FormBlockWrapper>
          <Button isLoading={isLoading} type="submit" isFullWidth>
            {t('registration.formCommonFields.submitButton')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
