import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActivateCompanyVariables = SchemaTypes.Exact<{
  input: SchemaTypes.ActivateCompanyInput;
}>;

export type ActivateCompany = {
  __typename: 'Mutation';
  activateCompany: {
    __typename: 'ActivateCompanyPayload';
    company: { __typename: 'Company'; id: string };
  };
};

export const ActivateCompanyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ActivateCompany' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivateCompanyInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activateCompany' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type ActivateCompanyMutationFn = Apollo.MutationFunction<
  ActivateCompany,
  ActivateCompanyVariables
>;

/**
 * __useActivateCompany__
 *
 * To run a mutation, you first call `useActivateCompany` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCompany` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCompany, { data, loading, error }] = useActivateCompany({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActivateCompany(
  baseOptions?: Apollo.MutationHookOptions<
    ActivateCompany,
    ActivateCompanyVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ActivateCompany, ActivateCompanyVariables>(
    ActivateCompanyDocument,
    options
  );
}
export type ActivateCompanyHookResult = ReturnType<typeof useActivateCompany>;
export type ActivateCompanyMutationResult =
  Apollo.MutationResult<ActivateCompany>;
export type ActivateCompanyMutationOptions = Apollo.BaseMutationOptions<
  ActivateCompany,
  ActivateCompanyVariables
>;
