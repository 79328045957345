import { Login } from 'pages/Login';
import { Registration } from 'pages/Registration';
import { RegistrationPartner } from 'pages/Registration/pages/RegistrationPartner';
import { RegistrationCompany } from 'pages/Registration/pages/RegistrationCompany';
import { Application } from 'pages/Application';
import { Statistic } from 'pages/Statistic';
import { Tariffs } from 'pages/Tariffs';
import { TwoFactor } from 'pages/TwoFactor';
import { Media } from 'pages/Media';
import { Support } from 'pages/Support';
import { NotFound } from 'pages/NotFound';
import { TwoFactorConnected } from 'pages/TwoFactorConnected';
import { Profile } from 'pages/Profile';
import { Reapplication } from 'pages/Reapplication';
import { PasswordReset } from 'pages/PasswordReset';
import { PasswordChange } from 'pages/PasswordChange';
import { CompanyPartners } from 'pages/CompanyPartners';
import { Payments } from 'pages/Payments';
import { AppRoute } from 'const';
import { Navigate, RouteObject } from 'react-router';
import { AuthLayout } from '../layouts/AuthLayout';
import { ErrorLayout } from '../layouts/ErrorLayout';
import { MainLayout } from '../layouts/MainLayout';
import { PrivateRouteWrapper } from './components/PrivateRouteWrapper';
import { RedirectByUserStatusWrapper } from './components/RedirectByUserStatusWrapper';
import { ActiveUserWrapper } from './components/ActiveUserWrapper';
import { NotActiveUserWrapper } from './components/NotActiveUserWrapper';

const publicRoutes: Array<RouteObject> = [
  {
    element: <AuthLayout />,
    children: [
      {
        element: <RedirectByUserStatusWrapper />,
        children: [
          {
            path: AppRoute.Login,
            element: <Login />,
          },
          {
            path: AppRoute.Registration,
            element: <Registration />,
          },
          {
            path: AppRoute.RegistrationPartner,
            element: <RegistrationPartner />,
          },
          {
            path: AppRoute.RegistrationCompany,
            element: <RegistrationCompany />,
          },
          {
            path: AppRoute.PasswordReset,
            element: <PasswordReset />,
          },
          {
            path: AppRoute.PasswordChange,
            element: <PasswordChange />,
          },
        ],
      },
      {
        path: AppRoute.TwoFactor,
        element: <TwoFactor />,
      },
      {
        path: AppRoute.TwoFactorConnected,
        element: <TwoFactorConnected />,
      },
      {
        path: AppRoute.Support,
        element: <Support />,
      },
    ],
  },
  {
    element: <ErrorLayout />,
    children: [
      {
        path: AppRoute.NotFound,
        element: <NotFound />,
      },
    ],
  },
];

const privateRoutes: Array<RouteObject> = [
  {
    element: <PrivateRouteWrapper />,
    children: [
      {
        element: <ActiveUserWrapper />,
        children: [
          {
            element: <MainLayout />,
            children: [
              {
                path: AppRoute.Main,
                element: <Navigate to={AppRoute.Statistic} />,
              },
              {
                path: AppRoute.Statistic,
                element: <Statistic />,
              },
              {
                path: AppRoute.Tariffs,
                element: <Tariffs />,
              },
              {
                path: AppRoute.Media,
                element: <Media />,
              },
              {
                path: AppRoute.Profile,
                element: <Profile />,
              },
              {
                path: AppRoute.CompanyPartners,
                element: <CompanyPartners />,
              },
              {
                path: AppRoute.Payments,
                element: <Payments />,
              },
            ],
          },
        ],
      },
      {
        element: <NotActiveUserWrapper />,
        children: [
          {
            element: <AuthLayout />,
            children: [
              {
                path: AppRoute.Application,
                element: <Application />,
              },
              {
                path: AppRoute.Reapplication,
                element: <Reapplication />,
              },
            ],
          },
        ],
      },
    ],
  },
];

export const routes: Array<RouteObject> = [...publicRoutes, ...privateRoutes];
