import { FC } from 'react';
import ReactModal from 'react-modal';
import { SCROLL_LOCKED_CLASS_NAME } from 'const';
import { MODAL_CONTAINER_ID } from './const';
import styles from './ModalContainer.module.scss';

type ModalContainerProps = Pick<
  ReactModal.Props,
  'isOpen' | 'onRequestClose' | 'children' | 'className'
>;

export const ModalContainer: FC<ModalContainerProps> = ({
  isOpen,
  onRequestClose,
  className,
  children,
}) => (
  <ReactModal
    id={MODAL_CONTAINER_ID}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    closeTimeoutMS={350}
    overlayClassName={{
      base: styles.overlay,
      afterOpen: styles.afterOpen,
      beforeClose: styles.beforeClose,
    }}
    className={className}
    bodyOpenClassName={SCROLL_LOCKED_CLASS_NAME}
    appElement={(document.querySelector('#root') as HTMLElement) || undefined}
  >
    {children}
  </ReactModal>
);
