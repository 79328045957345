import { t } from 'i18n';
import { FormikErrors } from 'formik';
import { checkEmailValidity } from 'utils/form/checkEmailValidity';
import { getRequiredErrors } from 'utils/form/getRequiredFormikErrors';
import type { PasswordResetValues } from './types';

export const validatePasswordReset = (
  values: PasswordResetValues
): FormikErrors<PasswordResetValues> => {
  const errors: FormikErrors<PasswordResetValues> = {};

  if (!checkEmailValidity(values.email)) {
    errors.email = t('errors.enterCorrectEmail');
  }

  const requiredErrors = getRequiredErrors(values, {
    email: true,
  });

  return {
    ...errors,
    ...requiredErrors,
  };
};
