export enum AppRoute {
  Main = '/',
  Login = '/login',
  Registration = '/registration',
  RegistrationPartner = '/registration/partner',
  RegistrationCompany = '/registration/company',
  Application = '/application',
  Statistic = '/statistic',
  Tariffs = '/tariffs',
  TwoFactor = '/two-factor',
  TwoFactorConnected = '/two-factor-connected',
  EmptyRoute = '/empty',
  Media = '/media',
  Support = '/support',
  Profile = '/profile',
  CompanyPartners = '/company-partners',
  Payments = '/payments',
  NotFound = '*',
  Reapplication = '/reapplication',
  PasswordReset = '/password/reset',
  PasswordChange = '/password/change',
}
