import { AuthStatuses, useAuth } from 'components/auth';
import { AppRoute } from 'const';
import { FC } from 'react';
import { Navigate, Outlet, Path, useLocation } from 'react-router';

export const RedirectByUserStatusWrapper: FC = () => {
  const { user } = useAuth();
  const { authStatus } = user;

  const location = useLocation();

  const redirectPathIfAuthenticated = (location.state as Path) || AppRoute.Main;

  if (authStatus === AuthStatuses.Authenticated) {
    return <Navigate to={redirectPathIfAuthenticated} replace />;
  }

  if (
    authStatus === AuthStatuses.TwoFactorAuthenticationRequired ||
    authStatus === AuthStatuses.TwoFactorGenerationRequired
  ) {
    return <Navigate to={AppRoute.TwoFactor} replace />;
  }

  return <Outlet />;
};
