import { useCallback } from 'react';
import { Currency } from 'types';
import { useAuth } from 'components/auth';
import { useSwitchPartnerNextRewardCurrency } from '../queries/generated/SwitchPartnerNextRewardCurrency';
import { useSwitchNextRewardCurrencyRequestHandlers } from './useSwitchNextRewardCurrencyRequestHandlers';

export const useSwitchPartnerNextRewardCurrencyMutation = (
  onCompleted: (currency: Currency) => void
) => {
  const [switchPartnerNextRewardCurrencyMutation, { loading }] =
    useSwitchPartnerNextRewardCurrency();
  const { handleCompleted, handleError } =
    useSwitchNextRewardCurrencyRequestHandlers({ onCompleted });
  const { user } = useAuth();

  const switchNextRewardCurrency = useCallback(
    (currency: Currency) => {
      if (!currency) {
        return undefined;
      }

      return switchPartnerNextRewardCurrencyMutation({
        variables: {
          input: {
            partnerId: user.id,
            currency,
          },
        },
        onError: (error) => handleError(error.message),
        onCompleted: (data) =>
          handleCompleted(
            data.switchPartnerNextRewardCurrency.partner.nextRewardInfo.currency
          ),
      });
    },
    [
      handleCompleted,
      handleError,
      switchPartnerNextRewardCurrencyMutation,
      user.id,
    ]
  );

  return {
    switchNextRewardCurrency,
    loading,
  };
};
