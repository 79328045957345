import { FormikErrors } from 'formik';
import i18n from 'i18n';

/**
 * Validates the minimum length of fields and returns error messages.
 *
 * @param {T} values - The form values to be validated.
 * @param {Partial<Record<keyof T, number>>} minLengthFields - An object specifying the minimum length required for each field.
 *
 * @returns {FormikErrors<T>} - An object containing error messages for each field that does not meet the minimum length requirement.
 *
 * @throws Will throw an error if the provided `values` is not an object.
 *
 * @example
 * const errors = getMinLengthErrors({ name: 'Jo', bio: 'Loves coding' }, { name: 3, bio: 10 });
 * console.log(errors); // { name: i18n.t('errors.minFieldError', { minFieldLength: 3 }), bio: i18n.t('errors.minFieldError', { minFieldLength: 10 }) }
 */

type StringValues<T> = {
  [P in keyof T]: string;
};

export const getMinLengthErrors = <T extends object & StringValues<T>>(
  values: T,
  minLengthFields: Partial<Record<keyof T, number>>
): FormikErrors<T> => {
  const errors: Partial<Record<keyof T, string | undefined>> = {};

  Object.keys(values).forEach((key) => {
    const typedKey = key as keyof T;
    const value = values[typedKey];
    const minLength = minLengthFields[typedKey];

    if (!value || !minLength) {
      return;
    }

    if (value.length < minLength) {
      errors[typedKey] = i18n.t('errors.minFieldError', {
        minFieldLength: minLength,
      });
    }
  });

  return errors as FormikErrors<T>;
};
