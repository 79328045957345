import type { FC } from 'react';
import { TextLink } from 'ui/TextLink/TextLink';
import styles from './TextWithLink.module.scss';

interface TextWithLinkProps {
  text: string;
  linkText: string;
  to: string;
  important?: boolean;
}

export const TextWithLink: FC<TextWithLinkProps> = ({
  text,
  linkText,
  to,
  important,
}) => (
  <div className={styles.container}>
    {text}{' '}
    <TextLink className={styles.link} to={to} isImportant={important}>
      {linkText}
    </TextLink>
  </div>
);
