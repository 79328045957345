import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCompanyNameVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetCompanyName = {
  __typename: 'Query';
  user:
    | { __typename: 'Company'; name: string; id: string }
    | { __typename: 'Partner'; id: string };
};

export const GetCompanyNameDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetCompanyName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetCompanyName__
 *
 * To run a query within a React component, call `useGetCompanyName` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyName` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyName({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCompanyName(
  baseOptions: Apollo.QueryHookOptions<GetCompanyName, GetCompanyNameVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCompanyName, GetCompanyNameVariables>(
    GetCompanyNameDocument,
    options
  );
}
export function useGetCompanyNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCompanyName,
    GetCompanyNameVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCompanyName, GetCompanyNameVariables>(
    GetCompanyNameDocument,
    options
  );
}
export type GetCompanyNameHookResult = ReturnType<typeof useGetCompanyName>;
export type GetCompanyNameLazyQueryHookResult = ReturnType<
  typeof useGetCompanyNameLazyQuery
>;
export type GetCompanyNameQueryResult = Apollo.QueryResult<
  GetCompanyName,
  GetCompanyNameVariables
>;
