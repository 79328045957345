import { FC } from 'react';
import { Country } from 'types';
import { getCountryNameByCode } from 'utils/countries/getCountryNameByCode';
import { FlagIcon } from 'ui/FlagIcon';
import styles from './CountryWithFlag.module.scss';

interface CountryWithFlagProps {
  country: Country | string;
}

export const CountryWithFlag: FC<CountryWithFlagProps> = ({ country }) => (
  <div className={styles.container}>
    <FlagIcon countryOrCurrencyCode={country} />
    {getCountryNameByCode(country)}
  </div>
);
