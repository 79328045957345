import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from 'react-router-dom';
import logoSrc from 'assets/images/logo.svg';
import { AppRoute } from 'const';
import styles from './ErrorLayout.module.scss';

export const ErrorLayout: FC = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <Link to={AppRoute.Main}>
        <img src={logoSrc} alt={t('logoAlt')} className={styles.logo} />
      </Link>
      <div className={styles.content}>
        <Outlet />
      </div>
    </div>
  );
};
