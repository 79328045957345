import { useTranslation } from 'react-i18next';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { getMoneyAmount } from 'utils/currency/getMoneyAmount';
import { CopyLink } from 'ui/CopyLink';
import { PartnerRevShareStatisticItemData } from '../queries/fragments/generated/PartnerRevShareStatisticItemData';

export const ALL_MEDIA_MIN_WIDTH = 232;
export const NUMBERS_CELL_WIDTH = 152;

export const usePartnerRevShareStatisticItemsColumns = (): Array<
  ColumnDef<PartnerRevShareStatisticItemData>
> => {
  const { t } = useTranslation(['common', 'statistic']);
  const columnHelper = createColumnHelper<PartnerRevShareStatisticItemData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<PartnerRevShareStatisticItemData, any>> = [
    columnHelper.accessor('id', {
      header: t('statistic:table.mediaName'),
      cell: ({ row }) => (
        <MultilineCellWithIcon
          title={row.original.name}
          subTitle={
            <CopyLink
              tooltipText={t('linkCopied')}
              to={row.original.link}
              target="_blank"
            >
              {row.original.link}
            </CopyLink>
          }
        />
      ),
      maxSize: ALL_MEDIA_MIN_WIDTH,
      enablePinning: true,
    }),
    columnHelper.accessor('statisticInfo.hits', {
      header: t('statistic:table.hits'),
      cell: ({ renderValue }) => renderValue(),
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.hosts', {
      header: t('statistic:table.hosts'),
      cell: ({ renderValue }) => renderValue(),
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.registrations', {
      header: t('statistic:table.registrations'),
      cell: ({ renderValue }) => renderValue(),
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.firstDeposits', {
      header: t('statistic:table.firstDeposits'),
      cell: ({ renderValue }) => renderValue(),
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.depositsAll', {
      header: t('statistic:table.depositsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.withdrawalsAll', {
      header: t('statistic:table.withdrawalsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.depositsFee', {
      header: t('statistic:table.depositsFee'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.withdrawalsFee', {
      header: t('statistic:table.withdrawalsFee'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.adminFee', {
      header: t('statistic:table.adminFee'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.reward', {
      header: t('statistic:table.reward'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.averageDeposit', {
      header: t('statistic:table.averageDeposit'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.redepositsAll', {
      header: t('statistic:table.redepositsAll'),
      cell: ({ getValue }) => getMoneyAmount(getValue()),
      minSize: NUMBERS_CELL_WIDTH,
    }),

    columnHelper.accessor('statisticInfo.conversionHostsToRegPercent', {
      header: t('statistic:table.conversionHostsToRegPercent'),
      cell: ({ getValue }) => `${getValue()}%`,
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.conversionRegToDepPercent', {
      header: t('statistic:table.conversionRegToDepPercent'),
      cell: ({ getValue }) => `${getValue()}%`,
      minSize: NUMBERS_CELL_WIDTH,
    }),
    columnHelper.accessor('statisticInfo.numberOfRedeposits', {
      header: t('statistic:table.numberOfRedeposits'),
      cell: ({ renderValue }) => renderValue(),
      minSize: NUMBERS_CELL_WIDTH,
    }),
  ];

  return columns;
};
