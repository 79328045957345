import * as SchemaTypes from '../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUserStatusVariables = SchemaTypes.Exact<{
  id: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetUserStatus = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        companyStatus: SchemaTypes.CompanyStatus;
      }
    | {
        __typename: 'Partner';
        id: string;
        partnerStatus: SchemaTypes.PartnerStatus;
      };
};

export const GetUserStatusDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetUserStatus' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'id' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'partnerStatus' },
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        alias: { kind: 'Name', value: 'companyStatus' },
                        name: { kind: 'Name', value: 'status' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetUserStatus__
 *
 * To run a query within a React component, call `useGetUserStatus` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStatus` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStatus({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserStatus(
  baseOptions: Apollo.QueryHookOptions<GetUserStatus, GetUserStatusVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserStatus, GetUserStatusVariables>(
    GetUserStatusDocument,
    options
  );
}
export function useGetUserStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserStatus,
    GetUserStatusVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserStatus, GetUserStatusVariables>(
    GetUserStatusDocument,
    options
  );
}
export type GetUserStatusHookResult = ReturnType<typeof useGetUserStatus>;
export type GetUserStatusLazyQueryHookResult = ReturnType<
  typeof useGetUserStatusLazyQuery
>;
export type GetUserStatusQueryResult = Apollo.QueryResult<
  GetUserStatus,
  GetUserStatusVariables
>;
