import { FC, KeyboardEvent, useEffect, useState } from 'react';
import { DatePicker, DatePickerHandleProps } from 'ui/DatePicker';
import { Input } from 'ui/formItems';
import classnames from 'classnames';
import { useField } from 'formik';
import { ReactComponent as Calendar } from 'assets/icons/line/Calendar.svg';
import ClickOutside, { useClickOutside } from 'ui/ClickOutside';
import { ReactDatePickerProps } from 'react-datepicker';
import { parse } from 'date-fns';
import i18n from 'i18next';
import { DateFormat, formatDate } from '../../utils/formatDate';
import styles from './DatePickerWithInput.module.scss';

interface DatePickerWithInputProps extends ReactDatePickerProps {
  onChange: (date: DatePickerHandleProps) => void;
  label: string;
  disabled?: boolean;
  placeholder?: string;
  name: string;
  isOpen?: boolean;
  openInitialDate?: Date;
}

const dateFormat = DateFormat.DefaultDate;

export const DatePickerWithInput: FC<DatePickerWithInputProps> = ({
  onChange,
  name,
  label,
  disabled,
  wrapperClassName,
  openInitialDate,
  isOpen = false,
  placeholder = `${i18n.t('datePicker.typeDate')} ${dateFormat}`,
  ...rest
}) => {
  const [field, meta] = useField(name);
  const { touched, error } = meta;

  const [startDate, setStartDate] = useState(field.value || null);

  const [isOpenState, setIsOpenState] = useState(isOpen);
  const [inputValue, setInputValue] = useState(
    startDate && formatDate(startDate, dateFormat)
  );

  const handleSubmit = (date: DatePickerHandleProps) => {
    setStartDate(date);
    onChange(date);
    if (date) {
      setIsOpenState(false);
    }
  };

  const handleChange = (date: DatePickerHandleProps) => {
    setStartDate(date);
  };

  useEffect(() => {
    setInputValue(startDate && formatDate(startDate, dateFormat));
  }, [startDate]);

  const handleChangeInput = (value: string) => {
    setInputValue(value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.code === 'Enter') {
      const formattedDate = parse(inputValue, dateFormat, new Date());

      if (!Number.isNaN(formattedDate.getDate())) {
        setStartDate(formattedDate);

        return;
      }

      setStartDate(null);
    }
  };

  const handleClickOutside = useClickOutside(() => {
    setIsOpenState(false);
    setStartDate(field.value);
  });

  return (
    <div className={classnames(wrapperClassName, styles.datePickerWrapper)}>
      <ClickOutside callback={handleClickOutside}>
        {/* todo replace this input to input without form logic */}
        <Input
          leftIconConfig={{ icon: Calendar }}
          label={label}
          onClick={() => setIsOpenState(true)}
          name="datePickerInput"
          disabled={disabled}
          onKeyDown={handleKeyDown}
          value={inputValue || ''}
          placeholder={placeholder}
          onChange={handleChangeInput}
          inputClassName={classnames(
            touched && styles.pickerTouched,
            touched && error && styles.pickerError
          )}
        />
        {isOpenState && (
          <DatePicker
            {...rest}
            openInitialDate={openInitialDate}
            className={styles.dropdown}
            onChange={handleChange}
            startDateInitial={startDate}
            onSubmit={handleSubmit}
          />
        )}
      </ClickOutside>
    </div>
  );
};
