import { FC } from 'react';
import ReactDatePicker, {
  registerLocale,
  setDefaultLocale,
  ReactDatePickerProps,
} from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import { useDatePickerData } from 'ui/DatePicker/hooks/useDatePickerData';
import classnames from 'classnames';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { DatePickerHandleProps } from './types';
import styles from './DatePicker.module.scss';

registerLocale('ru', ru);

setDefaultLocale('ru');

interface DatePickerProps extends ReactDatePickerProps {
  isSelectsRange?: boolean;
  onSubmit: (date: DatePickerHandleProps) => void;
  onChange: (date: DatePickerHandleProps) => void;
  disabled?: boolean;
  className?: string;
  startDateInitial?: Date | null;
  endDateInitial?: Date | null;
  openInitialDate?: Date;
}

export const DatePicker: FC<DatePickerProps> = ({
  isSelectsRange = false,
  onSubmit,
  startDateInitial,
  endDateInitial,
  disabled,
  className,
  onChange,
  openInitialDate,
  ...rest
}) => {
  const {
    datePickerStates,
    setDatePickerStates,
    openDate,
    setSelectsRange,
    startDate,
    endDate,
    handleChange,
    selectsRange,
    handleReset,
    handleSubmit,
  } = useDatePickerData({
    isSelectsRange,
    endDateInitial,
    onSubmit,
    startDateInitial,
    onChange,
    openInitialDate,
  });

  return (
    <div className={classnames(styles.datePickerContainer, className)}>
      <ReactDatePicker
        {...rest}
        openToDate={openDate}
        renderCustomHeader={(props) => (
          <Header
            datePickerStates={datePickerStates}
            setDatePickerStates={setDatePickerStates}
            setSelectsRange={setSelectsRange}
            datePickerCustomHeaderProps={props}
          />
        )}
        startDate={startDate}
        endDate={endDate}
        onChange={handleChange}
        selected={startDate}
        selectsRange={selectsRange}
        yearItemNumber={200}
        inline
        locale="ru"
        showMonthYearPicker={datePickerStates.showMonthYearPicker}
        showFullMonthYearPicker={datePickerStates.showFullMonthYearPicker}
        showYearPicker={datePickerStates.showYearPicker}
      />
      <Footer onReset={handleReset} onSubmit={handleSubmit} />
    </div>
  );
};
