import { useTranslation } from 'react-i18next';
import { ReactComponent as LinkArrowIcon } from 'assets/icons/line/LinkArrow.svg';
import { CardWithIcon } from 'ui/CardWithIcon';

interface WithdrawRewardCardProps {
  wrapperClassName?: string;
}

export const WithdrawRewardCard = ({
  wrapperClassName,
}: WithdrawRewardCardProps) => {
  const { t } = useTranslation('payments');

  return (
    <CardWithIcon
      className={wrapperClassName}
      title={t('withdrawRewardTitle')}
      subTitle={t('withdrawRewardSubtitle')}
      onClick={() => {}}
      icon={LinkArrowIcon}
    />
  );
};
