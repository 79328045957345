import { useCallback } from 'react';
import { toast } from 'ui/toast';
import { Currency } from 'types';
import { checkIsCurrency } from 'utils/currency/checkIsCurrency';
import { useAuth } from 'components/auth';
import { useSwitchPartnerCurrentCurrencyAccount } from '../queries/generated/SwitchPartnerCurrentCurrencyAccount';

export const useSwitchPartnerCurrentCurrencyAccountMutation = (
  onCompleted: (currency: Currency) => void
) => {
  const [changeCurrencyPaymentMutation, { loading }] =
    useSwitchPartnerCurrentCurrencyAccount();
  const { user } = useAuth();

  const handleCompleted = useCallback(
    (currency: string) => {
      if (checkIsCurrency(currency)) {
        return onCompleted?.(currency);
      }

      throw new Error('Unknown currency type');
    },
    [onCompleted]
  );

  const handleError = useCallback((errorMessage: string) => {
    toast.error({
      header: 'Error',
      text: errorMessage,
    });
  }, []);

  const switchCurrentCurrencyAccount = useCallback(
    (currency: Currency) => {
      if (!currency) {
        return undefined;
      }

      return changeCurrencyPaymentMutation({
        variables: {
          input: {
            currency,
            partnerId: user.id,
          },
        },
        onError: (error) => handleError(error.message),
        onCompleted: () => handleCompleted(currency),
      });
    },
    [changeCurrencyPaymentMutation, handleCompleted, handleError, user.id]
  );

  return {
    switchCurrentCurrencyAccount,
    loading,
  };
};
