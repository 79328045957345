import { useNavigate } from 'react-router';
import { ApolloError } from '@apollo/client';
import { AppRoute } from 'const';
import { toast } from 'ui/toast';

export const useReapplyMutationHandlers = () => {
  const navigate = useNavigate();

  const handleCompleted = () => {
    navigate(AppRoute.Application);
  };

  const handleError = (error: ApolloError) => {
    toast.error({
      header: 'Error',
      text: error.message,
      toastId: 'reapplyApplicationError',
    });
  };

  return {
    handleCompleted,
    handleError,
  };
};
