import { useTranslation } from 'react-i18next';
import { Card } from 'ui/Card';
import { getCurrencyDisplayName } from 'utils/currency/getCurrencyDisplayName';
import { checkIsCurrency } from 'utils/currency/checkIsCurrency';
import { getCurrencySymbol } from 'utils/currency/getCurrencySymbol';
import { CurrencyAccountDropdown } from './components/CurrencyAccountDropdown';
import { useGetAvailableCurrencyAccountsQuery } from './hooks/useGetAvailableCurrencyAccountsQuery';
import styles from './BalanceCard.module.scss';

interface BalanceCardProps {
  wrapperClassName?: string;
}

export const BalanceCard = ({ wrapperClassName }: BalanceCardProps) => {
  const { t } = useTranslation('payments');

  /* TODO: when get design, add handling loading and error state
     https://gitlab.com/eyecon/vavada/partner/frontend/-/merge_requests/240
  */
  const { availableCurrencyAccounts, loading, error } =
    useGetAvailableCurrencyAccountsQuery();
  const currentCurrencyAccount = availableCurrencyAccounts?.find(
    (availableCurrencyAccount) => availableCurrencyAccount?.isCurrent
  );

  if (loading) {
    return 'Loading';
  }

  if (
    !availableCurrencyAccounts ||
    error ||
    !checkIsCurrency(currentCurrencyAccount?.currency) ||
    !currentCurrencyAccount?.currency
  ) {
    return 'Error';
  }

  return (
    <Card
      title={t('balance')}
      headerRightBlock={
        <CurrencyAccountDropdown
          currentCurrencyAccount={currentCurrencyAccount}
          availableCurrencyAccounts={availableCurrencyAccounts}
          loading={loading}
        />
      }
      hasHeaderSeparator={false}
      className={wrapperClassName}
    >
      <div className={styles.innerWrapper}>
        <div className={styles.balanceWrapper}>
          <p className={styles.balance}>{currentCurrencyAccount.amount}</p>
          <p className={styles.currency}>
            {currentCurrencyAccount.currency} —{' '}
            {getCurrencySymbol({
              currency: currentCurrencyAccount.currency,
            })}
          </p>
        </div>
        <span className={styles.bottomSubTitle}>
          {getCurrencyDisplayName(currentCurrencyAccount.currency)}
        </span>
      </div>
    </Card>
  );
};
