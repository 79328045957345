import { useAuth } from 'components/auth';
import { useGetPostbackInfo } from '../queries/generated/GetPostbackInfo';

interface UseGetPostbackInfoMutation {
  mediaItemId: string;
}

export const useGetPostbackInfoQuery = ({
  mediaItemId,
}: UseGetPostbackInfoMutation) => {
  const { user } = useAuth();

  const { data, error, loading } = useGetPostbackInfo({
    variables: {
      mediaItemId,
      userId: user.id,
    },
  });

  const postbackInfo = data?.user.mediaItem.postbackInfo;

  return {
    postbackInfo,
    error,
    loading,
  };
};
