import * as SchemaTypes from '../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
export type PostbackInfoData = {
  __typename: 'ReferralLinkMediaItem';
  id: string;
  postbackInfo: {
    __typename: 'PostbackInfo';
    commonPostbackStatus: SchemaTypes.CommonPostbackStatus;
    events: {
      __typename: 'PostbackEvents';
      availableEvents: Array<SchemaTypes.PostbackEvent>;
      totalEventsCount: number;
    };
    postbacks?: Array<{
      __typename: 'Postback';
      event: SchemaTypes.PostbackEvent;
      id: string;
      isConnected: boolean;
      method: SchemaTypes.PostbackMethod;
      url: string;
    }> | null;
  };
};

export const PostbackInfoData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostbackInfoData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MediaItemInterface' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'InlineFragment',
            typeCondition: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ReferralLinkMediaItem' },
            },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'postbackInfo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'commonPostbackStatus' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'events' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'availableEvents' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'totalEventsCount' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'postbacks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'event' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isConnected' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'method' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'url' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
