import { AppRoute } from 'const';
import { useTwoFactorRequired } from 'hooks/useTwoFactorRequired';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

export const useRedirectToApp = () => {
  const navigate = useNavigate();
  const { isTwoFactorRequired } = useTwoFactorRequired();

  const location = useLocation();
  const { from } = (location.state as { from: { pathname: string } }) || {
    from: { pathname: AppRoute.Main },
  };

  useEffect(() => {
    if (!isTwoFactorRequired) {
      navigate(from, { replace: true });
    }
  }, [from, isTwoFactorRequired, navigate]);
};
