import { useTranslation } from 'react-i18next';
import { toast } from 'ui/toast';
import { ApolloError } from '@apollo/client';
import { ListAllOperations } from 'types/generated/gql';
import { useAuth } from 'components/auth';
import { useCreatePartnerReferralLinkMediaItem } from '../queries/generated/CreatePartnerReferralLinkMediaItem';
import { CreatePartnerMediaFormValues } from '../types';

interface UseCreatePartnerReferralLinkMediaItemMutationParams {
  onCompleted?: () => void;
}

export const useCreatePartnerReferralLinkMediaItemMutation = ({
  onCompleted,
}: UseCreatePartnerReferralLinkMediaItemMutationParams) => {
  const { user } = useAuth();
  const { t } = useTranslation('media');
  const [create, { loading }] = useCreatePartnerReferralLinkMediaItem();

  const handleCompleted = () => {
    toast.success(t('mediaCreated'));
    onCompleted?.();
  };

  const handleError = (error: ApolloError) => {
    toast.error({
      header: 'Error',
      text: error.message,
    });
  };

  const createReferralLinkMediaItem = ({
    mediaItemName,
    tariffId,
    mediaCampaignId,
  }: CreatePartnerMediaFormValues) => {
    create({
      variables: {
        input: {
          partnerId: user.id,
          mediaCampaignId,
          name: mediaItemName,
          tariffId,
        },
      },
      onCompleted: handleCompleted,
      onError: handleError,
      refetchQueries: [ListAllOperations.Query.GetMediaItems],
    });
  };

  return { createReferralLinkMediaItem, loading };
};
