import { FC, useEffect } from 'react';
import { UserTypeName } from 'types';
import { Tabs } from 'ui/Tabs';
import { TabsSize } from 'ui/Tabs/const';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { useModalContext, updateConfirmClose } from 'ui/Modal';
import { useConfirmModalContext } from 'ui/ConfirmModal';
import { useEditingIdsContext } from 'contexts/EditingIdsContext';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import styles from './EditMediaModal.module.scss';
import { useGetMediaItemQuery } from './hooks/useGetMediaItemQuery';
import { createTabs, getTabIndex } from './helpers';
import { EditTab } from './const';

interface EditMediaModalProps {
  mediaItemId: string;
  defaultActiveTab?: EditTab;
}

export const EditMediaModal: FC<EditMediaModalProps> = ({
  mediaItemId,
  defaultActiveTab,
}) => {
  const { dispatch } = useModalContext();
  const { showConfirmation } = useConfirmModalContext();
  const { clearEditingElementsIds, isEditInProcess } = useEditingIdsContext();

  const {
    userTypeName,
    loading: getUserTypeLoading,
    error: getUserTypeError,
  } = useGetUserTypeNameQuery();

  const {
    mediaItem,
    loading: getMediaItemLoading,
    error: getMediaItemError,
  } = useGetMediaItemQuery({ mediaItemId });

  const handleTabChange = () => {
    clearEditingElementsIds();
  };

  useEffect(() => {
    if (isEditInProcess) {
      dispatch(
        updateConfirmClose({
          confirmClose: showConfirmation,
        })
      );
    } else {
      dispatch(
        updateConfirmClose({
          confirmClose: null,
        })
      );
    }
  }, [dispatch, isEditInProcess, showConfirmation]);

  const isCompany = userTypeName === UserTypeName.Company;
  const isLoading = getMediaItemLoading || getUserTypeLoading;

  const isError =
    !mediaItem || getMediaItemError || !userTypeName || getUserTypeError;

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error error={getMediaItemError || getUserTypeError} />;
  }

  return (
    <Tabs
      onBeforeTabChange={isEditInProcess ? showConfirmation : undefined}
      defaultActiveTab={
        defaultActiveTab && getTabIndex(defaultActiveTab, isCompany)
      }
      tabs={createTabs(mediaItem, isCompany)}
      tabsControlsClass={styles.tabs}
      size={TabsSize.Small}
      onTabChange={handleTabChange}
    />
  );
};
