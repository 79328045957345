import { useAuth } from 'components/auth';
import { UserTypeName } from 'types';
import { useGetPartnerCpaStatisticTotal } from '../queries/generated/GetPartnerCpaStatisticTotal';

export const useGetPartnerCpaStatisticTotalQuery = () => {
  const { user } = useAuth();

  const { data, loading, error } = useGetPartnerCpaStatisticTotal({
    variables: {
      userId: user.id,
    },
    notifyOnNetworkStatusChange: true,
  });

  const totalStatistic =
    data?.user.__typename === UserTypeName.Partner
      ? data.user.cpaStatistic.total
      : undefined;

  return { totalStatistic, loading, error };
};
