import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SPEC_HEADER_LARGE } from 'const';
import styles from './TwoFactorCheckBlock.module.scss';
import { TwoFactorSendForm } from '../TwoFactorSendForm';

export const TwoFactorCheckBlock: FC = () => {
  const { t } = useTranslation(['common', 'auth']);

  return (
    <>
      <h1 className={SPEC_HEADER_LARGE}>
        {t('auth:twoFactorConfirmation.header')}
      </h1>
      <div className={styles.textBlock}>
        <p>{t('auth:twoFactorConfirmation.description')}</p>
      </div>
      <TwoFactorSendForm
        disabledButtonTooltip={t(
          'auth:twoFactorConfirmation.disabledButtonTooltip'
        )}
        inputLabel={t('auth:twoFactorConfirmation.inputLabel')}
        buttonText={t('enterButton')}
      />
    </>
  );
};
