import { t } from 'i18n';
import { CommonPostbackStatus } from 'types/generated/gql';
import { ButtonTheme } from 'ui/Button';

export const mapPostbackStatusToButtonTheme: Record<
  CommonPostbackStatus,
  ButtonTheme
> = {
  [CommonPostbackStatus.NotConnected]: ButtonTheme.BlueGrey,
  [CommonPostbackStatus.Connected]: ButtonTheme.BlueGhost,
  [CommonPostbackStatus.Disconnected]: ButtonTheme.RedGhost,
  [CommonPostbackStatus.PartiallyDisconnected]: ButtonTheme.RedGhost,
};

export const mapPostbackStatusToTooltip: Record<CommonPostbackStatus, string> =
  {
    [CommonPostbackStatus.NotConnected]: t('media:postbackStatus.notConnected'),
    [CommonPostbackStatus.Connected]: t('media:postbackStatus.connected'),
    [CommonPostbackStatus.Disconnected]: t('media:postbackStatus.disconnected'),
    [CommonPostbackStatus.PartiallyDisconnected]: t(
      'media:postbackStatus.partiallyDisconnected'
    ),
  };
