import { useAuth } from 'components/auth';
import i18n from 'i18n';
import { AppRoute } from 'const';
import { UserPrivilege } from 'types/generated/userPrivilege';
import { useLogout } from 'hooks/useLogout';
import { MenuItemData } from 'ui/Menu/types';
import { ReactComponent as DashboardIcon } from 'assets/icons/colors/Dashboard.svg';
import { ReactComponent as StatisticIcon } from 'assets/icons/colors/Chart.svg';
import { ReactComponent as TariffIcon } from 'assets/icons/colors/Calculator.svg';
import { ReactComponent as MediaIcon } from 'assets/icons/colors/Image.svg';
import { ReactComponent as PartnersListIcon } from 'assets/icons/colors/PartnersList.svg';
import { ReactComponent as PaymentIcon } from 'assets/icons/colors/WadOfMoney.svg';
import { ReactComponent as UserIcon } from 'assets/icons/colors/User.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/colors/Logout.svg';

interface UseGetMenuData {
  topMenuData: Array<MenuItemData>;
  bottomMenuData: Array<MenuItemData>;
}

export const useGetMenuData = (): UseGetMenuData => {
  const auth = useAuth();
  const { logout } = useLogout();

  return {
    topMenuData: [
      {
        icon: DashboardIcon,
        link: AppRoute.EmptyRoute,
        text: i18n.t('pageTitles.dashboard'),
        isHidden: false,
      },
      {
        icon: StatisticIcon,
        link: AppRoute.Statistic,
        text: i18n.t('pageTitles.statistic'),
        isHidden: false,
      },
      {
        icon: TariffIcon,
        link: AppRoute.Tariffs,
        text: i18n.t('pageTitles.tariff'),
        isHidden: !auth.privileges.isSomeGranted([
          UserPrivilege.ViewPartnerTariffs,
          UserPrivilege.ViewCompanyTariffs,
        ]),
      },
      {
        icon: MediaIcon,
        link: AppRoute.Media,
        text: i18n.t('pageTitles.media'),
        isHidden: !auth.privileges.isSomeGranted([
          UserPrivilege.ViewPartnerMediaItem,
          UserPrivilege.ViewCompanyMediaItems,
        ]),
      },
      {
        icon: PartnersListIcon,
        link: AppRoute.CompanyPartners,
        text: i18n.t('pageTitles.partners'),
        isHidden: !auth.privileges.areEveryGranted([
          UserPrivilege.ViewCompanyPartners,
        ]),
      },
    ],
    bottomMenuData: [
      {
        icon: PaymentIcon,
        link: AppRoute.Payments,
        text: i18n.t('pageTitles.payments'),
        isHidden: !auth.privileges.isSomeGranted([
          UserPrivilege.ViewCompanyNextRewardInfo,
          UserPrivilege.ViewPartnerNextRewardInfo,
        ]),
      },
      {
        icon: UserIcon,
        link: AppRoute.Profile,
        text: i18n.t('pageTitles.account'),
        isHidden: !auth.privileges.areEveryGranted([UserPrivilege.ViewUser]),
      },
      {
        icon: LogoutIcon,
        onClick: () => logout(),
        text: i18n.t('logoutButtonShort'),
        isHidden: false,
      },
    ],
  };
};
