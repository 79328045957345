import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePostbackVariables = SchemaTypes.Exact<{
  input: SchemaTypes.CreatePostbackInput;
}>;

export type CreatePostback = {
  __typename: 'Mutation';
  createPostback: {
    __typename: 'CreatePostbackPayload';
    postback: {
      __typename: 'Postback';
      event: SchemaTypes.PostbackEvent;
      id: string;
      isConnected: boolean;
      method: SchemaTypes.PostbackMethod;
      url: string;
    };
  };
};

export const CreatePostbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'CreatePostback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'CreatePostbackInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createPostback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'postback' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'event' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'isConnected' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'method' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type CreatePostbackMutationFn = Apollo.MutationFunction<
  CreatePostback,
  CreatePostbackVariables
>;

/**
 * __useCreatePostback__
 *
 * To run a mutation, you first call `useCreatePostback` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePostback` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPostback, { data, loading, error }] = useCreatePostback({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePostback(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePostback,
    CreatePostbackVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePostback, CreatePostbackVariables>(
    CreatePostbackDocument,
    options
  );
}
export type CreatePostbackHookResult = ReturnType<typeof useCreatePostback>;
export type CreatePostbackMutationResult =
  Apollo.MutationResult<CreatePostback>;
export type CreatePostbackMutationOptions = Apollo.BaseMutationOptions<
  CreatePostback,
  CreatePostbackVariables
>;
