import { t } from 'i18n';
import { PostbackEvent } from 'types/generated/gql';

export const mapEventToCardTitle: Record<PostbackEvent, string> = {
  [PostbackEvent.FirstDeposit]: t('media:postbackCardTitle.firstDeposit'),
  [PostbackEvent.QualificationCheck]: t(
    'media:postbackCardTitle.qualificationCheck'
  ),
  [PostbackEvent.Registration]: t('media:postbackCardTitle.registration'),
  [PostbackEvent.Reward]: t('media:postbackCardTitle.reward'),
};
