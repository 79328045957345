import * as SchemaTypes from '../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { PartnerCpaStatisticInfoData } from './PartnerCpaStatisticInfoData';
import { MoneyAmountData } from '../../../../../../../queries/fragments/generated/MoneyAmountData';
export type PartnerCpaStatisticItemData = {
  __typename: 'PartnerCpaStatisticItem';
  id: string;
  link: string;
  name: string;
  statisticInfo: {
    __typename: 'PartnerCpaStatisticInfo';
    approved: number;
    conversionHostsToRegPercent: number;
    conversionRegToDepPercent: number;
    firstDeposits: number;
    hits: number;
    hold: number;
    hosts: number;
    numberOfRedeposits: number;
    paid: number;
    qualified: number;
    registrations: number;
    rejected: number;
    averageDeposit: {
      __typename: 'MoneyAmount';
      currency: string;
      value: number;
    };
    depositsAll: { __typename: 'MoneyAmount'; currency: string; value: number };
    redepositsAll: {
      __typename: 'MoneyAmount';
      currency: string;
      value: number;
    };
    reward: { __typename: 'MoneyAmount'; currency: string; value: number };
    withdrawalsAll: {
      __typename: 'MoneyAmount';
      currency: string;
      value: number;
    };
  };
};

export const PartnerCpaStatisticItemData = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PartnerCpaStatisticItemData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'PartnerCpaStatisticItem' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'link' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'statisticInfo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'PartnerCpaStatisticInfoData' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
