import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ListButton } from 'ui/ListButton';
import { ReactComponent as TelegramIcon } from 'assets/icons/solid/Telegram.svg';
import { ReactComponent as SkypeIcon } from 'assets/icons/solid/Skype.svg';
import styles from './SupportButtons.module.scss';

export const SUPPORT_USERNAME = '@acvavada';
export const SKYPE_LINK = 'https://join.skype.com/invite/pjT4qWeb7MqJ';
export const TELEGRAM_LINK = 'https://t.me/acvavada';

export const SupportButtons: FC = () => {
  const { t } = useTranslation();

  return (
    <div data-testid="support-buttons" className={styles.container}>
      <ListButton
        to={TELEGRAM_LINK}
        icon={TelegramIcon}
        title={t('messengers.telegram')}
        subTitle={SUPPORT_USERNAME}
      />
      <ListButton
        icon={SkypeIcon}
        to={SKYPE_LINK}
        title={t('messengers.skype')}
        subTitle={SUPPORT_USERNAME}
      />
    </div>
  );
};
