import { RadioCard } from 'components/RadioCard';
import { FC } from 'react';
import { ReactComponent as CompanyIcon } from 'assets/icons/solid/Case.svg';
import { ReactComponent as PartnerIcon } from 'assets/icons/solid/User.svg';
import { Form, Formik } from 'formik';
import { Button } from 'ui/Button';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ChooseRegistrationValues } from './type';
import { EntityTypeToRegister, FieldName } from './const';
import { mapChosenValueToRoute } from './helpers';
import styles from './ChooseRegistrationType.module.scss';

export const ChooseRegistrationType: FC = () => {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  const handleSubmit = ({ registrationType }: ChooseRegistrationValues) => {
    if (registrationType) {
      navigate({ pathname: mapChosenValueToRoute[registrationType] });
    }
  };

  return (
    <Formik
      initialValues={{ [FieldName.RegistrationType]: undefined }}
      onSubmit={handleSubmit}
    >
      {({ values }) => (
        <Form
          className={styles.form}
          data-testid="choose-registration-block"
          noValidate
        >
          <div className={styles.cardsBlock}>
            <RadioCard
              className={styles.card}
              title={t('registration.partnerCardTitle')}
              subTitle={t('registration.partnerCardSubTitle')}
              name={FieldName.RegistrationType}
              icon={PartnerIcon}
              value={EntityTypeToRegister.Partner}
            />
            <RadioCard
              className={styles.card}
              title={t('registration.companyCardTitle')}
              subTitle={t('registration.companyCardSubTitle')}
              name={FieldName.RegistrationType}
              icon={CompanyIcon}
              value={EntityTypeToRegister.Company}
            />
          </div>
          <Button type="submit" disabled={!values.registrationType} isFullWidth>
            {t('registration.register')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
