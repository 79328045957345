import * as SchemaTypes from '../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { PartnerCpaStatisticItemData } from '../fragments/generated/PartnerCpaStatisticItemData';
import { PartnerCpaStatisticInfoData } from '../fragments/generated/PartnerCpaStatisticInfoData';
import { MoneyAmountData } from '../../../../../../queries/fragments/generated/MoneyAmountData';
import { PageInfoData } from '../../../../../../queries/fragments/generated/PageInfoData';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetPartnerCpaStatisticItemsVariables = SchemaTypes.Exact<{
  after?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Cursor']['input']>;
  first?: SchemaTypes.InputMaybe<SchemaTypes.Scalars['Int']['input']>;
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetPartnerCpaStatisticItems = {
  __typename: 'Query';
  user:
    | { __typename: 'Company'; id: string }
    | {
        __typename: 'Partner';
        id: string;
        cpaStatistic: {
          __typename: 'PartnerCpaStatistic';
          statisticItems: {
            __typename: 'PartnerCpaStatisticItemsConnection';
            edges: Array<{
              __typename: 'PartnerCpaStatisticItemsConnectionEdge';
              cursor: string;
              node: {
                __typename: 'PartnerCpaStatisticItem';
                id: string;
                link: string;
                name: string;
                statisticInfo: {
                  __typename: 'PartnerCpaStatisticInfo';
                  approved: number;
                  conversionHostsToRegPercent: number;
                  conversionRegToDepPercent: number;
                  firstDeposits: number;
                  hits: number;
                  hold: number;
                  hosts: number;
                  numberOfRedeposits: number;
                  paid: number;
                  qualified: number;
                  registrations: number;
                  rejected: number;
                  averageDeposit: {
                    __typename: 'MoneyAmount';
                    currency: string;
                    value: number;
                  };
                  depositsAll: {
                    __typename: 'MoneyAmount';
                    currency: string;
                    value: number;
                  };
                  redepositsAll: {
                    __typename: 'MoneyAmount';
                    currency: string;
                    value: number;
                  };
                  reward: {
                    __typename: 'MoneyAmount';
                    currency: string;
                    value: number;
                  };
                  withdrawalsAll: {
                    __typename: 'MoneyAmount';
                    currency: string;
                    value: number;
                  };
                };
              };
            }>;
            pageInfo: {
              __typename: 'PageInfo';
              endCursor?: string | null;
              hasNextPage: boolean;
              hasPreviousPage: boolean;
              startCursor: string;
            };
          };
        };
      };
};

export const GetPartnerCpaStatisticItemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetPartnerCpaStatisticItems' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'after' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Cursor' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'first' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'cpaStatistic' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'statisticItems' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'after' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'after' },
                                  },
                                },
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'first' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'first' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'edges' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: {
                                            kind: 'Name',
                                            value: 'cursor',
                                          },
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'node' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'PartnerCpaStatisticItemData',
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'pageInfo' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'FragmentSpread',
                                          name: {
                                            kind: 'Name',
                                            value: 'PageInfoData',
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...PartnerCpaStatisticItemData.definitions,
    ...PartnerCpaStatisticInfoData.definitions,
    ...MoneyAmountData.definitions,
    ...PageInfoData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetPartnerCpaStatisticItems__
 *
 * To run a query within a React component, call `useGetPartnerCpaStatisticItems` and pass it any options that fit your needs.
 * When your component renders, `useGetPartnerCpaStatisticItems` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPartnerCpaStatisticItems({
 *   variables: {
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPartnerCpaStatisticItems(
  baseOptions: Apollo.QueryHookOptions<
    GetPartnerCpaStatisticItems,
    GetPartnerCpaStatisticItemsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetPartnerCpaStatisticItems,
    GetPartnerCpaStatisticItemsVariables
  >(GetPartnerCpaStatisticItemsDocument, options);
}
export function useGetPartnerCpaStatisticItemsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPartnerCpaStatisticItems,
    GetPartnerCpaStatisticItemsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetPartnerCpaStatisticItems,
    GetPartnerCpaStatisticItemsVariables
  >(GetPartnerCpaStatisticItemsDocument, options);
}
export type GetPartnerCpaStatisticItemsHookResult = ReturnType<
  typeof useGetPartnerCpaStatisticItems
>;
export type GetPartnerCpaStatisticItemsLazyQueryHookResult = ReturnType<
  typeof useGetPartnerCpaStatisticItemsLazyQuery
>;
export type GetPartnerCpaStatisticItemsQueryResult = Apollo.QueryResult<
  GetPartnerCpaStatisticItems,
  GetPartnerCpaStatisticItemsVariables
>;
