import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SPEC_HEADER_LARGE } from 'const';
import { UserTypeName } from 'types';
import { Button } from 'ui/Button';
import styles from './ApprovedBlock.module.scss';
import { useActivateUser } from './hooks/useActivateUser';

interface ApprovedBlockProps {
  userTypeName: UserTypeName.Company | UserTypeName.Partner;
}

export const ApprovedBlock: FC<ApprovedBlockProps> = ({ userTypeName }) => {
  const { t } = useTranslation('auth');

  const { activateUser, isLoading } = useActivateUser(userTypeName);

  const handleButtonClick = () => {
    activateUser();
  };

  return (
    <div data-testid="approved-block">
      <div className={styles.contentContainer}>
        <h1 className={SPEC_HEADER_LARGE}>{t('application.activeHeader')}</h1>
        <p>{t('application.activeDescription')}</p>
      </div>
      <Button isFullWidth isLoading={isLoading} onClick={handleButtonClick}>
        {t('application.activeButton')}
      </Button>
    </div>
  );
};
