import { components, GroupBase, MenuProps } from 'react-select';
import styles from './Menu.module.scss';
import { SelectOption } from '../../type';

export const Menu = (
  props: MenuProps<SelectOption, false, GroupBase<SelectOption>>
) => (
  <components.Menu<SelectOption, false, GroupBase<SelectOption>>
    className={styles.menu}
    {...props}
  />
);
