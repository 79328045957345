import { FC, useCallback } from 'react';
import { Table } from 'ui/Table';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { EmptyDataBlock } from 'components/EmptyDataBlock';
import { useTranslation } from 'react-i18next';
import { useGetCompanyPartnersQuery } from './hooks/useGetCompanyPartnersQuery';
import { useCompanyPartnersTableColumns } from './hooks/useCompanyPartnersTableColumns';

export const CompanyPartnersTable: FC = () => {
  const { t } = useTranslation('companyPartners');

  const { companyPartnersItems, pageInfo, fetchMore, error, loading } =
    useGetCompanyPartnersQuery();

  const columns = useCompanyPartnersTableColumns();

  const loadMore = useCallback(() => {
    if (!pageInfo?.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        after: pageInfo.endCursor,
      },
    });
  }, [fetchMore, pageInfo?.endCursor, pageInfo?.hasNextPage]);

  if (!companyPartnersItems && loading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!companyPartnersItems || companyPartnersItems.length === 0) {
    return <EmptyDataBlock emptyDataText={t('emptyData')} />;
  }

  return (
    <Table
      columns={columns}
      data={companyPartnersItems}
      isLoading={loading}
      hasMoreData={!!pageInfo?.hasNextPage}
      error={error}
      fetchMore={loadMore}
    />
  );
};
