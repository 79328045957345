import { ru } from 'date-fns/locale';
import { setDefaultOptions } from 'date-fns';
import { ToastContainer } from 'ui/toast';
import { AuthProvider } from 'components/auth';
import { AppRouter } from './components/AppRouter';
import { ApolloProvider } from './components/ApolloProvider';

setDefaultOptions({ locale: ru });

export const App = () => (
  <AuthProvider>
    <ApolloProvider>
      <AppRouter />
      <ToastContainer />
    </ApolloProvider>
  </AuthProvider>
);
