import { FC, MouseEventHandler } from 'react';
import { Button, ButtonSize } from 'ui/Button';
import { Tooltip } from 'ui/Tooltip';
import { CommonPostbackStatus } from 'types/generated/gql';
import { ReactComponent as PostbackIcon } from 'assets/icons/line/Postback.svg';
import {
  mapPostbackStatusToButtonTheme,
  mapPostbackStatusToTooltip,
} from './helpers';
import styles from './PostbackButton.module.scss';

interface PostbackButtonProps {
  status: CommonPostbackStatus;
  onClick: MouseEventHandler;
}

export const PostbackButton: FC<PostbackButtonProps> = ({
  onClick,
  status,
}) => (
  <Tooltip
    placement="top-end"
    tooltipTriggerClassName={styles.postbackButton}
    tooltipContent={mapPostbackStatusToTooltip[status]}
  >
    <Button
      data-testid="postback-button"
      size={ButtonSize.Small}
      theme={mapPostbackStatusToButtonTheme[status]}
      onClick={onClick}
      iconConfig={{
        iconComponent: PostbackIcon,
      }}
    />
  </Tooltip>
);
