import classNames from 'classnames';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { Dropdown, DropdownSize } from 'ui/Dropdown';
import { DropdownPosition } from 'ui/Dropdown/const';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/line/AltArrowDown.svg';
import { ReactComponent as ArrowUpIcon } from 'assets/icons/line/AltArrowUp.svg';
import { Currency } from 'types';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipTheme } from 'ui/Tooltip';
import { CurrencyAccount } from 'types/generated/gql';
import { checkIsCurrency } from 'utils/currency/checkIsCurrency';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { getDropdownItemsByCurrencies } from './helpers';
import { useDropdownState } from './hooks/useDropdownState';
import { getDropdownValueByCurrency } from './helpers/getDropdownValueByCurrency';
import { useSwitchCurrentCurrencyAccount } from './hooks/useSwitchCurrentCurrencyAccount';
import styles from './CurrencyAccountDropdown.module.scss';

interface CurrencyAccountDropdownProps {
  currentCurrencyAccount: CurrencyAccount;
  availableCurrencyAccounts: Array<CurrencyAccount | null>;
  loading: boolean;
}

export const CurrencyAccountDropdown = ({
  currentCurrencyAccount,
  availableCurrencyAccounts,
  loading = false,
}: CurrencyAccountDropdownProps) => {
  const { t } = useTranslation('payments');
  const { userTypeName } = useGetUserTypeNameQuery();

  const initialCurrency = checkIsCurrency(currentCurrencyAccount.currency)
    ? currentCurrencyAccount.currency
    : Currency.USD;

  const {
    isDropdownOpen,
    dropdownValue,
    toggleFocus,
    handleBlur,
    changeDropdownValue,
    closeDropdown,
  } = useDropdownState(initialCurrency);

  const handleCompleted = (currency: Currency) => {
    changeDropdownValue(currency);
    closeDropdown();
  };

  const {
    switchCurrentCurrencyAccount,
    loading: changeCurrencyPaymentLoading,
  } = useSwitchCurrentCurrencyAccount({
    onCompleted: handleCompleted,
    userTypeName,
  });

  const disabled =
    loading ||
    !availableCurrencyAccounts.length ||
    !currentCurrencyAccount?.currency ||
    availableCurrencyAccounts.length === 1 ||
    changeCurrencyPaymentLoading;

  const shouldShowTooltip = !loading && availableCurrencyAccounts.length === 1;

  const handleItemClick = (currency?: string) => {
    if (currency === currentCurrencyAccount.currency) {
      closeDropdown();

      return;
    }

    if (checkIsCurrency(currency)) {
      switchCurrentCurrencyAccount(currency);
    }
  };

  return (
    <div className={styles.wrapper} onBlur={handleBlur}>
      <Tooltip
        placement="bottom"
        tooltipTheme={TooltipTheme.Dark}
        tooltipContent={
          shouldShowTooltip ? t('accountPaymentDropdownDisabled') : undefined
        }
        shouldSetWidthFitContent={false}
      >
        <Button
          onClick={toggleFocus}
          className={classNames(styles.button, isDropdownOpen && styles.active)}
          iconConfig={{
            iconComponent: isDropdownOpen ? ArrowUpIcon : ArrowDownIcon,
          }}
          theme={ButtonTheme.Grey}
          size={ButtonSize.Small}
          disabled={disabled}
        >
          <div className={styles.innerButtonContent}>
            {dropdownValue.icon && dropdownValue.icon}
            {checkIsCurrency(dropdownValue?.value) &&
              getDropdownValueByCurrency(dropdownValue.value)}
          </div>
        </Button>
      </Tooltip>
      <Dropdown
        items={getDropdownItemsByCurrencies(
          availableCurrencyAccounts,
          handleItemClick
        )}
        isOpen={isDropdownOpen}
        size={DropdownSize.Small}
        position={DropdownPosition.Right}
        highlightOnHover
      />
    </div>
  );
};
