import { t } from 'i18n';
import { PostbackEvent, PostbackMethod } from 'types/generated/gql';

export const mapEventToName: Record<PostbackEvent, string> = {
  [PostbackEvent.FirstDeposit]: t('media:postbackEvents.firstDeposit'),
  [PostbackEvent.QualificationCheck]: t(
    'media:postbackEvents.qualificationCheck'
  ),
  [PostbackEvent.Registration]: t('media:postbackEvents.registration'),
  [PostbackEvent.Reward]: t('media:postbackEvents.reward'),
};

export const mapMethodToName: Record<PostbackMethod, string> = {
  [PostbackMethod.Get]: t('media:postbackMethods.get'),
  [PostbackMethod.Post]: t('media:postbackMethods.post'),
};
