import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SPEC_HEADER_LARGE } from 'const';
import { useGetUserLastApplicationQuery } from 'hooks/queryHooks/useGetUserLastApplicationQuery';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { ReapplicationForm } from '../ReapplicationForm';
import styles from './ReapplicationBlock.module.scss';

export const ReapplicationBlock: FC = () => {
  const { t } = useTranslation('auth');
  const { lastApplication, loading, error } = useGetUserLastApplicationQuery();

  if (loading) {
    return <Loader />;
  }

  if (error || !lastApplication) {
    return <Error error={error} />;
  }

  const { contact, trafficType, __typename } = lastApplication;

  return (
    <div className={styles.container}>
      <h1 className={SPEC_HEADER_LARGE}>{t('reapplication.header')}</h1>
      <p className={styles.description}>{t('reapplication.description')}</p>
      <ReapplicationForm
        contact={contact}
        trafficType={trafficType}
        applicationTypeName={__typename}
      />
    </div>
  );
};
