import { DropdownPosition, DropdownSize } from '../const';
import styles from '../Dropdown.module.scss';

export const mapPositionToClassName: Record<DropdownPosition, string> = {
  [DropdownPosition.Right]: styles.rightPosition,
  [DropdownPosition.Left]: styles.leftPosition,
};

export const mapSizeToClassName: Record<DropdownSize, string> = {
  [DropdownSize.Small]: styles.small,
  [DropdownSize.Large]: styles.large,
};
