import { Country, Currency } from 'types';
import { checkIsCountryCode } from 'utils/countries/checkIsCountryCode';
import { checkIsCurrency } from 'utils/currency/checkIsCurrency';

const ROOT_FLAG_UNICODE_INDEX = 127397;

export const getFlagEmojiByCountryCode = (countryCode: Country): string => {
  const codePoints = countryCode
    .split('')
    .map((char) => ROOT_FLAG_UNICODE_INDEX + char.charCodeAt(0));

  return String.fromCodePoint(...codePoints);
};

export const getCountryCodeByCurrencyCode = (
  currencyCode: Currency | string
): Country | null => {
  if (!checkIsCurrency(currencyCode)) {
    return null;
  }

  const countryCode = currencyCode.slice(0, 2).toUpperCase();

  return checkIsCountryCode(countryCode) ? countryCode : null;
};
