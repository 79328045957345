import { FC } from 'react';
import { VerticalLayout } from 'ui/VerticalLayout';
import { LogoutButton } from 'components/LogoutButton';
import { ReapplicationBlock } from './components/ReapplicationBlock';

export const Reapplication: FC = () => (
  <VerticalLayout
    topBlock={<LogoutButton />}
    centralBlock={<ReapplicationBlock />}
  />
);
