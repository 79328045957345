import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { AvailableCurrencyAccountsData } from '../fragments/generated/AvailableCurrencyAccountsData';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAvailableCurrencyAccountsVariables = SchemaTypes.Exact<{
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetAvailableCurrencyAccounts = {
  __typename: 'Query';
  user:
    | { __typename: 'Company'; id: string }
    | {
        __typename: 'Partner';
        id: string;
        availableCurrencyAccounts: Array<{
          __typename: 'CurrencyAccount';
          amount?: number | null;
          currency: string;
          id: string;
          isCurrent: boolean;
        } | null>;
      };
};

export const GetAvailableCurrencyAccountsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetAvailableCurrencyAccounts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'availableCurrencyAccounts',
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'AvailableCurrencyAccountsData',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...AvailableCurrencyAccountsData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetAvailableCurrencyAccounts__
 *
 * To run a query within a React component, call `useGetAvailableCurrencyAccounts` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableCurrencyAccounts` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableCurrencyAccounts({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetAvailableCurrencyAccounts(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailableCurrencyAccounts,
    GetAvailableCurrencyAccountsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableCurrencyAccounts,
    GetAvailableCurrencyAccountsVariables
  >(GetAvailableCurrencyAccountsDocument, options);
}
export function useGetAvailableCurrencyAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableCurrencyAccounts,
    GetAvailableCurrencyAccountsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableCurrencyAccounts,
    GetAvailableCurrencyAccountsVariables
  >(GetAvailableCurrencyAccountsDocument, options);
}
export type GetAvailableCurrencyAccountsHookResult = ReturnType<
  typeof useGetAvailableCurrencyAccounts
>;
export type GetAvailableCurrencyAccountsLazyQueryHookResult = ReturnType<
  typeof useGetAvailableCurrencyAccountsLazyQuery
>;
export type GetAvailableCurrencyAccountsQueryResult = Apollo.QueryResult<
  GetAvailableCurrencyAccounts,
  GetAvailableCurrencyAccountsVariables
>;
