import { useMemo } from 'react';
import { useAuth } from 'components/auth';
import { useGetMediaItems } from '../../../queries/generated/GetMediaItems';
import { MediaItemBasicData } from '../../../queries/fragments/generated/MediaItemBasicData';

const MEDIA_ITEMS_PER_PAGE = 10;

export const useGetMediaItemsQuery = () => {
  const { user } = useAuth();

  const { data, fetchMore, error, loading } = useGetMediaItems({
    variables: {
      userId: user.id,
      first: MEDIA_ITEMS_PER_PAGE,
    },
    notifyOnNetworkStatusChange: true,
  });

  const mediaItemsConnection = data?.user.mediaItems;

  const mediaItems: Array<MediaItemBasicData> | undefined = useMemo(
    () => mediaItemsConnection?.edges.map((item) => item.node),
    [mediaItemsConnection?.edges]
  );

  const pageInfo = mediaItemsConnection?.pageInfo;

  return {
    mediaItems,
    pageInfo,
    fetchMore,
    error,
    loading,
  };
};
