import { FC } from 'react';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { useTranslation } from 'react-i18next';
import { KeyValueGrid } from 'ui/KeyValueGrid';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { getTariffItemData } from './helpers/getTariffItemData';
import styles from './TariffItem.module.scss';
import { useGetUserTariffQuery } from './hooks/useGetUserTariff';

interface TariffItemProps {
  tariffId: string;
}

export const TariffItem: FC<TariffItemProps> = ({ tariffId }) => {
  const { t } = useTranslation('tariffs');
  const { tariff, loading, error } = useGetUserTariffQuery(tariffId);

  if (!tariff) {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Error error={error} />;
  }

  const tariffData = getTariffItemData(tariff);

  return (
    <>
      <KeyValueGrid
        className={styles.keyValueGrid}
        data={tariffData}
        shouldStackOnMd
      />
      <Button size={ButtonSize.Large} theme={ButtonTheme.BlueLight}>
        {t('media')}
      </Button>
    </>
  );
};
