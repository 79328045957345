import { ApolloError } from '@apollo/client';
import { Dispatch, SetStateAction } from 'react';
import { AppRoute } from 'const';
import { toast } from 'ui/toast';
import { PasswordResetValues } from '../types';
import { useResetUserPassword } from '../queries/generated/ResetUserPassword';

interface UsePasswordResetParams {
  setEmailSent: Dispatch<SetStateAction<boolean>>;
}

export const usePasswordReset = ({ setEmailSent }: UsePasswordResetParams) => {
  const [resetUserPassword, { loading }] = useResetUserPassword();

  const handleCompleted = () => {
    setEmailSent(true);
  };

  const handleError = (error: ApolloError) => {
    toast.error({
      header: 'Error',
      text: error.message,
      toastId: 'passwordResetError',
    });
  };

  const passwordReset = (values: PasswordResetValues) => {
    resetUserPassword({
      variables: {
        input: {
          currentEmail: values.email,
          setPasswordPath: AppRoute.PasswordChange,
        },
      },
      onCompleted: handleCompleted,
      onError: handleError,
    });
  };

  return {
    passwordReset,
    loading,
  };
};
