import { useState, useEffect, useCallback, FocusEvent } from 'react';
import { Currency } from 'types';
import { DropdownValue } from '../types';
import { getDropdownStateByCurrency } from '../helpers';

interface UseDropdownStateProps {
  dropdownValue: DropdownValue;
  toggleFocus: () => void;
  handleBlur: (event: FocusEvent<HTMLDivElement>) => void;
  changeDropdownValue: (currency: Currency) => void;
  closeDropdown: () => void;
  isDropdownOpen: boolean;
}

export const useDropdownState = (
  initialCurrency: Currency
): UseDropdownStateProps => {
  const [isFocused, setIsFocused] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(
    getDropdownStateByCurrency(initialCurrency)
  );

  useEffect(() => {
    setIsDropdownOpen(!!dropdownValue.value && isFocused);
  }, [dropdownValue.value, isFocused]);

  useEffect(() => {
    setDropdownValue(getDropdownStateByCurrency(initialCurrency));
  }, [initialCurrency]);

  const toggleFocus = useCallback(() => {
    setIsFocused((prevState) => !prevState);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleBlur = useCallback((event: FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      setIsFocused(false);
    }
  }, []);

  const changeDropdownValue = useCallback((currency: Currency) => {
    setDropdownValue(getDropdownStateByCurrency(currency));
  }, []);

  return {
    isDropdownOpen,
    dropdownValue,
    toggleFocus,
    handleBlur,
    changeDropdownValue,
    closeDropdown,
  };
};
