import { FC, useEffect, useMemo } from 'react';
import { Form, useFormikContext } from 'formik';
import { ProfileFormHeader } from 'pages/Profile/components/ProfileDataBlock/components/ProfileFormHeader';
import { useTranslation } from 'react-i18next';
import { FormBlockWrapper, Input, SelectAndInput } from 'ui/formItems';
import { DatePickerWithInput } from 'ui/DatePickerWithInput';
import { contactTypeOption } from 'helpers';
import {
  IndentBetweenRowsSize,
  MarginBottomSize,
} from 'ui/formItems/components/FormBlockWrapper/const';
import { FieldName } from '../../const';
import { PartnerIdentitiesValues } from '../../types';
import styles from './PartnerProfileFormContent.module.scss';

interface PartnerProfileFormContentProps {
  loading: boolean;
  onDirtyChange: (dirty: boolean) => void;
}

export const PartnerProfileFormContent: FC<PartnerProfileFormContentProps> = ({
  loading,
  onDirtyChange,
}) => {
  const { t } = useTranslation('profile');
  const { values, isValid, dirty, errors, setFieldValue } =
    useFormikContext<PartnerIdentitiesValues>();

  const maxDateForDatePicker = useMemo(() => new Date(), []);

  useEffect(() => {
    onDirtyChange(dirty);
  }, [dirty, onDirtyChange]);

  return (
    <Form>
      <ProfileFormHeader isDisabled={!isValid || !dirty} isLoading={loading} />
      <FormBlockWrapper
        indentBetweenRowSize={IndentBetweenRowsSize.Large}
        marginBottomSize={MarginBottomSize.Large}
      >
        <div className={styles.identitiesContainer}>
          <Input name={FieldName.FirstName} label={t('firstName')} />
          <Input name={FieldName.LastName} label={t('lastName')} />
        </div>
        <DatePickerWithInput
          label={t('birthday')}
          name={FieldName.Birthday}
          onChange={(value) => setFieldValue(FieldName.Birthday, value)}
          maxDate={maxDateForDatePicker}
        />
        <SelectAndInput
          selectProps={{
            name: FieldName.ContactType,
            label: t('contactMethod'),
            options: contactTypeOption,
          }}
          inputProps={{
            name: FieldName.ContactValue,
            label: t('contactText'),
            disabled: !values.contactType,
          }}
          isError={Boolean(errors.contactValue)}
        />
      </FormBlockWrapper>
    </Form>
  );
};
