import { IconSize } from '../../Icon';
import { DropdownSize } from '../const';

export const getIconSize = (dropdownSize?: DropdownSize) => {
  switch (dropdownSize) {
    case DropdownSize.Small:
      return IconSize.Small;
    default:
      return IconSize.Large;
  }
};
