import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TopBar } from 'components/TopBar';
import { ReactComponent as PartnersListIcon } from 'assets/icons/colors/PartnersList.svg';
import { useApplicationToAddPartnersModal } from './hooks/useApplicationToAddPartnersModal';
import { CompanyPartnersTable } from './components/CompanyPartnersTable';

export const CompanyPartners: FC = () => {
  const { t } = useTranslation(['common', 'companyPartners']);
  const { openAddPartnerModal } = useApplicationToAddPartnersModal();

  return (
    <>
      <TopBar
        title={t('pageTitles.companyPartners')}
        icon={PartnersListIcon}
        action={{
          buttonText: t('companyPartners:addPartner'),
          onClick: openAddPartnerModal,
        }}
      />
      <CompanyPartnersTable />
    </>
  );
};
