import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Card } from 'ui/Card';
import { Input, Textarea } from 'ui/formItems';
import { Button, ButtonSize, ButtonTheme } from 'ui/Button';
import { ReactComponent as TrashIcon } from 'assets/icons/line/Trash.svg';
import { FieldName } from '../../const';
import {
  ApplicationToAddPartnersFormItem,
  ApplicationToAddPartnersFormValues,
} from '../../types';
import { ActionButtons } from './components/ActionButtons';
import { areAllFieldsValid } from './helpers';
import { mapFiledNameToMaxLength, MAX_PARTNERS } from './const';
import styles from './PartnerCardList.module.scss';

interface PartnerCardListProps {
  push: (card: ApplicationToAddPartnersFormItem) => void;
  remove: (index: number) => void;
  onDirtyChange: (dirty: boolean) => void;
  isSubmitting?: boolean;
}

export const PartnerCardList: FC<PartnerCardListProps> = ({
  push,
  remove,
  onDirtyChange,
  isSubmitting = false,
}) => {
  const { t } = useTranslation('companyPartners');
  const { values, dirty } =
    useFormikContext<ApplicationToAddPartnersFormValues>();
  const isSubmitButtonDisabled = areAllFieldsValid(
    values[FieldName.CompanyPartners]
  );
  const isAddCardButtonDisabled =
    values[FieldName.CompanyPartners].length >= MAX_PARTNERS || isSubmitting;

  const addPartnerCard = (index?: number) => {
    if (!index) {
      return;
    }

    push({
      [FieldName.Username]: '',
      [FieldName.Note]: null,
      [FieldName.Id]: index + 1,
    });
  };

  const removePartnerCard = (index: number) => {
    remove(index);
  };

  useEffect(() => {
    onDirtyChange(dirty);
  }, [dirty, onDirtyChange]);

  return (
    <>
      <div>
        {values[FieldName.CompanyPartners].map((partner, index) => (
          <Card
            className={styles.card}
            key={partner.id}
            title={`${t('addPartnerModal.cardTitle')} ${partner.id}`}
          >
            <Input
              wrapperClassName={styles.input}
              name={`${FieldName.CompanyPartners}[${index}].${FieldName.Username}`}
              label={t('addPartnerModal.userName')}
              maxLength={mapFiledNameToMaxLength[FieldName.Username]}
              disabled={isSubmitting}
            />
            <Textarea
              wrapperClassName={styles.input}
              name={`${FieldName.CompanyPartners}[${index}].${FieldName.Note}`}
              label={t('addPartnerModal.note')}
              maxLength={mapFiledNameToMaxLength[FieldName.Note]}
              disabled={isSubmitting}
            />
            <Button
              onClick={() => removePartnerCard(index)}
              className={styles.deleteButton}
              size={ButtonSize.Medium}
              theme={ButtonTheme.RedGrey}
              iconConfig={{
                iconComponent: TrashIcon,
                before: true,
              }}
              disabled={
                values[FieldName.CompanyPartners].length === 1 || isSubmitting
              }
            >
              {t('addPartnerModal.deleteCardButton')}
            </Button>
          </Card>
        ))}
      </div>
      <ActionButtons
        onAddButtonClick={() =>
          addPartnerCard(values[FieldName.CompanyPartners].at(-1)?.id)
        }
        isSubmitButtonDisabled={isSubmitButtonDisabled}
        isAddCardButtonDisabled={isAddCardButtonDisabled}
        isSubmitting={isSubmitting}
      />
    </>
  );
};
