import { FC, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SPEC_HEADER_LARGE, AppRoute } from 'const';
import { VerticalLayout } from 'ui/VerticalLayout';
import { Button } from 'ui/Button';
import { AuthStorageKey, Storage } from 'utils/storage';
import { useNavigate } from 'react-router';
import styles from './TwoFactorConnected.module.scss';

export const TwoFactorConnected: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['common', 'auth']);

  const isTwoFactorJustCreated = Storage.authStorage.getValue(
    AuthStorageKey.ShowTwoFactorSuccess
  );

  const navigateToMain = useCallback(() => {
    navigate({ pathname: AppRoute.Main });
  }, [navigate]);

  const handleClick = () => {
    Storage.authStorage.removeValue(AuthStorageKey.ShowTwoFactorSuccess);
    navigateToMain();
  };

  useEffect(() => {
    if (!isTwoFactorJustCreated) {
      navigateToMain();
    }
  }, [isTwoFactorJustCreated, navigateToMain]);

  return (
    <VerticalLayout
      centralBlock={
        <>
          <div className={styles.contentContainer}>
            <h1 className={SPEC_HEADER_LARGE}>
              {t('auth:twoFactorConnected.header')}
            </h1>
            <p>{t('auth:twoFactorConnected.description')}</p>
          </div>
          <Button isFullWidth onClick={handleClick}>
            {t('enterButton')}
          </Button>
        </>
      }
    />
  );
};
