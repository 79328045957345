import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { ReactComponent as UserIcon } from 'assets/icons/colors/User.svg';
import { TopBar } from 'components/TopBar';
import { ConfirmModal, ConfirmModalContextProvider } from 'ui/ConfirmModal';
import { ProfileDataBlock } from './components/ProfileDataBlock';

export const Profile: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <TopBar title={t('pageTitles.account')} icon={UserIcon} />
      <ConfirmModalContextProvider>
        <ProfileDataBlock />
        <ConfirmModal
          content={{
            cancelButton: t('pageCloseConfirmModalContent.cancelButton'),
            confirmButton: t('pageCloseConfirmModalContent.confirmButton'),
            text: t('pageCloseConfirmModalContent.text'),
            title: t('pageCloseConfirmModalContent.title'),
          }}
        />
      </ConfirmModalContextProvider>
    </>
  );
};
