import { FC, useCallback } from 'react';
import { Table } from 'ui/Table';
import { Error } from 'ui/Error';
import { Loader } from 'ui/Loader';
import { EmptyDataBlock } from 'components/EmptyDataBlock';
import { useTranslation } from 'react-i18next';
import { UserTypeName } from 'types';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { useOpenModal } from '../../hooks/useOpenModal';
import { MediaItemBasicData } from '../../queries/fragments/generated/MediaItemBasicData';
import { useGetMediaItemsQuery } from './hooks/useGetMediaItemsQuery';
import { useMediaTableColumns } from './hooks/useMediaTableColumns';

export const MediaTable: FC = () => {
  const { openEditModal } = useOpenModal();
  const { t } = useTranslation('media');

  const {
    userTypeName,
    loading: getUserTypeLoading,
    error: getUserTypeError,
  } = useGetUserTypeNameQuery();

  const {
    mediaItems,
    pageInfo,
    fetchMore,
    error: getMediaItemsError,
    loading: getMediaItemsLoading,
  } = useGetMediaItemsQuery();

  const columns = useMediaTableColumns({
    isCompany: userTypeName === UserTypeName.Company,
  });

  const loadMore = useCallback(() => {
    if (!pageInfo?.hasNextPage) {
      return;
    }

    fetchMore({
      variables: {
        after: pageInfo.endCursor,
      },
    });
  }, [fetchMore, pageInfo?.endCursor, pageInfo?.hasNextPage]);

  const handleRowClick = ({ id }: MediaItemBasicData) => {
    openEditModal(id);
  };

  const isLoading = (!mediaItems && getMediaItemsLoading) || getUserTypeLoading;
  const error = getUserTypeError || getMediaItemsError;

  if (isLoading) {
    return <Loader shouldExcludeMenuWidth />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!mediaItems || mediaItems.length === 0) {
    return <EmptyDataBlock emptyDataText={t('emptyData')} />;
  }

  return (
    <Table
      onClickOnRow={handleRowClick}
      columns={columns}
      data={mediaItems}
      isLoading={getMediaItemsLoading}
      hasMoreData={!!pageInfo?.hasNextPage}
      error={getMediaItemsError}
      fetchMore={loadMore}
    />
  );
};
