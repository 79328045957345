import i18n from 'i18n';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { PaymentModel } from 'components/PaymentModel';
import { CopyLink } from 'ui/CopyLink';
import { TableCellAlign } from 'ui/Table';
import { MultilineCellWithIcon } from 'ui/Table/components/MultilineCellWithIcon';
import { UserTypeName } from 'types';
import { MayBeNullCell } from 'ui/Table/components/MayBeNullCell';
import { MediaItemBasicData } from '../../../queries/fragments/generated/MediaItemBasicData';
import { useOpenModal } from '../../../hooks/useOpenModal';
import { EditTab } from '../../EditMediaModal/const';
import { PostbackButton } from '../components/PostbackButton';

export const NAME_AND_ID_COLUMN_MIN_WIDTH = 232;
const LINK_COLUMN_MAX_WIDTH = 642;

const { t } = i18n;

interface UseMediaTableColumns {
  isCompany: boolean;
}

export const useMediaTableColumns = ({
  isCompany,
}: UseMediaTableColumns): Array<ColumnDef<MediaItemBasicData>> => {
  const { openEditModal } = useOpenModal();

  const columnHelper = createColumnHelper<MediaItemBasicData>();

  /*
   *  We need to use any as generic argument while bug won't be fixed
   *  Issue https://github.com/TanStack/table/issues/4382
   */
  const columns: Array<ColumnDef<MediaItemBasicData, any>> = [
    columnHelper.accessor('id', {
      header: t('media:nameAndId'),
      cell: ({ row }) => (
        <MultilineCellWithIcon
          title={row.original.name}
          subTitle={row.original.id}
        />
      ),
      minSize: NAME_AND_ID_COLUMN_MIN_WIDTH,
      enablePinning: true,
    }),
    ...(isCompany
      ? [
          columnHelper.accessor('owner', {
            header: t('media:owner'),
            cell: ({ row }) => {
              if (row.original.owner.__typename === UserTypeName.Company) {
                return (
                  <MultilineCellWithIcon
                    title={row.original.owner.name}
                    subTitle={t('company')}
                  />
                );
              }

              if (row.original.owner.__typename === 'CompanyPartner') {
                return row.original.owner.username;
              }

              return <MayBeNullCell />;
            },
          }),
        ]
      : []),
    columnHelper.accessor('link', {
      header: t('media:mediaLink'),
      cell: (info) => (
        <CopyLink
          onClick={(e) => e.stopPropagation()}
          target="_blank"
          to={info.getValue()}
          tooltipText={t('linkCopied')}
        >
          {info.getValue()}
        </CopyLink>
      ),
      maxSize: LINK_COLUMN_MAX_WIDTH,
    }),
    columnHelper.accessor('tariff', {
      header: t('media:tariffs.tariff'),
      cell: ({ row }) => row.original.tariff.name,
    }),
    columnHelper.accessor('tariff', {
      id: 'paymentModel',
      header: t('media:tariffs.paymentModel'),
      cell: ({ row }) => (
        <PaymentModel tariffTypeName={row.original.tariff.__typename} />
      ),
    }),
    columnHelper.accessor('postbackInfo', {
      header: t('media:postback'),
      cell: ({ row }) => (
        <PostbackButton
          status={row.original.postbackInfo.commonPostbackStatus}
          onClick={(e) => {
            e.stopPropagation();
            openEditModal(row.original.id, EditTab.Postbacks);
          }}
        />
      ),
      meta: {
        align: TableCellAlign.Center,
      },
    }),
  ];

  return columns;
};
