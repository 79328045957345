import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Select } from 'ui/formItems';
import { PostbackEvent } from 'types/generated/gql';
import styles from './SelectsBlock.module.scss';
import { FieldName } from '../../../Postback/const';
import { mapEventToName } from '../../../Postback/helpers';
import { methodOptions } from './const';
import { CreationState } from '../../../../types';

interface SelectsBlockProps {
  isLoading: boolean;
  creationState?: CreationState;
  events: Array<PostbackEvent>;
}

export const SelectsBlock: FC<SelectsBlockProps> = ({
  isLoading,
  creationState,
  events,
}) => {
  const { t } = useTranslation('media');

  const eventOptions = useMemo(
    () =>
      events.map((event) => ({
        label: mapEventToName[event],
        value: event,
      })),
    [events]
  );

  return (
    <div className={styles.selectsContainer} data-testid="selects-block">
      <div className={styles.leftSelect}>
        <Select
          disabled={
            !creationState || creationState.isCreationInProcess || isLoading
          }
          label={t('eventLabel')}
          formValidationProps={{ errorTooltipPlacement: 'left' }}
          name={FieldName.Event}
          options={eventOptions}
        />
      </div>
      <div className={styles.rightSelect}>
        <Select
          disabled={isLoading}
          label={t('methodLabel')}
          formValidationProps={{ errorTooltipPlacement: 'left' }}
          name={FieldName.Method}
          options={methodOptions}
          hasMinWidth={false}
        />
      </div>
    </div>
  );
};
