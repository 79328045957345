import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { TariffData } from './TariffData';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetTariffVariables = SchemaTypes.Exact<{
  tariffId: SchemaTypes.Scalars['ID']['input'];
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetTariff = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        tariff:
          | {
              __typename: 'CpaTariff';
              geolocation: Array<CommonType.Country>;
              createdAt: string;
              id: string;
              name: string;
              baseline: {
                __typename: 'MoneyAmount';
                currency: string;
                value: number;
              };
              reward: {
                __typename: 'MoneyAmount';
                currency: string;
                value: number;
              };
            }
          | {
              __typename: 'RevShareTariff';
              rewardPercent: string;
              createdAt: string;
              id: string;
              name: string;
            };
      }
    | {
        __typename: 'Partner';
        id: string;
        tariff:
          | {
              __typename: 'CpaTariff';
              geolocation: Array<CommonType.Country>;
              createdAt: string;
              id: string;
              name: string;
              baseline: {
                __typename: 'MoneyAmount';
                currency: string;
                value: number;
              };
              reward: {
                __typename: 'MoneyAmount';
                currency: string;
                value: number;
              };
            }
          | {
              __typename: 'RevShareTariff';
              rewardPercent: string;
              createdAt: string;
              id: string;
              name: string;
            };
      };
};

export const GetTariffDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetTariff' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'tariffId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tariff' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'tariffId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'TariffData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tariff' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'id' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'tariffId' },
                            },
                          },
                        ],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'TariffData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TariffData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetTariff__
 *
 * To run a query within a React component, call `useGetTariff` and pass it any options that fit your needs.
 * When your component renders, `useGetTariff` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariff({
 *   variables: {
 *      tariffId: // value for 'tariffId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetTariff(
  baseOptions: Apollo.QueryHookOptions<GetTariff, GetTariffVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTariff, GetTariffVariables>(
    GetTariffDocument,
    options
  );
}
export function useGetTariffLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTariff, GetTariffVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTariff, GetTariffVariables>(
    GetTariffDocument,
    options
  );
}
export type GetTariffHookResult = ReturnType<typeof useGetTariff>;
export type GetTariffLazyQueryHookResult = ReturnType<
  typeof useGetTariffLazyQuery
>;
export type GetTariffQueryResult = Apollo.QueryResult<
  GetTariff,
  GetTariffVariables
>;
