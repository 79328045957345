import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'ui/Tabs';
import { TopBar } from 'components/TopBar';
import { ReactComponent as StatisticIcon } from 'assets/icons/colors/Chart.svg';
import { PartnerRevShareStatisticTable } from './components/PartnerRevShareStatisticTable';
import { PartnerCpaStatisticTable } from './components/PartnerCpaStatisticTable';
import styles from './Statistic.module.scss';

export const Statistic: FC = () => {
  const { t } = useTranslation(['common', 'statistic']);

  const tabs = [
    {
      title: t('statistic:tabs.revShare'),
      content: <PartnerRevShareStatisticTable />,
    },
    {
      title: t('statistic:tabs.cpa'),
      content: <PartnerCpaStatisticTable />,
    },
  ];

  return (
    <>
      <TopBar title={t('pageTitles.statistic')} icon={StatisticIcon} />
      <Tabs tabs={tabs} tabsControlsClass={styles.tabs} />
    </>
  );
};
