import { t } from 'i18n';
import { PostbackMacros } from 'types/generated/gql';

export const mapMacroToValue: Record<PostbackMacros, string> = {
  [PostbackMacros.ClickId]: '{click_id}',
  [PostbackMacros.Currency]: '{reward}',
  [PostbackMacros.Reward]: '{currency}',
  [PostbackMacros.Tid]: '{tid}',
};

export const mapMacroToDescription: Record<PostbackMacros, string> = {
  [PostbackMacros.ClickId]: t('media:postbackMacrosDescription.clickId'),
  [PostbackMacros.Currency]: t('media:postbackMacrosDescription.currency'),
  [PostbackMacros.Reward]: t('media:postbackMacrosDescription.reward'),
  [PostbackMacros.Tid]: t('media:postbackMacrosDescription.tid'),
};
