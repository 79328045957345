import jwtDecode from 'jwt-decode';
import { useRef, useState } from 'react';
import { JwtData, Token, User } from '../types';
import { AuthStatuses, defaultUser } from '../const';
import { AuthStatusesBackend, TokenBodyResponse } from '../api/types';
import { isDeepEqual } from '../../../utils/isDeepEqual';

interface UseAuthUserDataResult {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  accessToken: React.MutableRefObject<Token>;
  updateUserData: (data: TokenBodyResponse) => void;
}

export const useAuthUserData = (): UseAuthUserDataResult => {
  const [user, setUser] = useState<User>(defaultUser);
  const accessToken = useRef<Token>({ token: '', expiredAt: 0 });

  const updateUserData = (data: TokenBodyResponse): void => {
    const result = { ...user };
    const { status, token } = data;

    if (token) {
      const decodedTokenData = jwtDecode<JwtData>(token);

      accessToken.current.token = token;
      accessToken.current.expiredAt = decodedTokenData.exp;
      result.name = decodedTokenData.identifier;
      result.id = decodedTokenData.userId;
      result.privileges = decodedTokenData.privileges;
    }

    if (status) {
      result.authStatus = AuthStatuses[status as AuthStatusesBackend];
    }

    if (!isDeepEqual(user, result)) {
      setUser(result);
    }
  };

  return {
    user,
    setUser,
    accessToken,
    updateUserData,
  };
};
