import { Currency } from 'types/common';

/**
 * Returns the symbol of a specified currency for a given locale.
 *
 * @param currency - The type of currency.
 * @param locale - The desired locale (default is 'ru').
 *
 * @example
 * getCurrencySymbol({ currency: Currency.USD });  // Returns: '$'
 */
interface GetCurrencySymbolParams {
  currency: Currency;
  locale?: string | Array<string>;
}

export const getCurrencySymbol = ({
  currency,
  locale = 'ru',
}: GetCurrencySymbolParams): string => {
  const formatOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
  };

  const symbol = new Intl.NumberFormat(locale, formatOptions)
    .format(1)
    .replace(/[0-9,.\s]/g, '')
    .trim();

  return symbol;
};
