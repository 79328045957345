import * as CommonType from '../common';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /**
   * Country-code is uppercase ISO 3166 two-letter code (alpha-2)
   * https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes)
   * Example: AQ
   */
  CountryCode: { input: CommonType.Country; output: CommonType.Country };
  /**
   * Any string
   * Example: 9f06ce7cdca9ed9c074d33c4c9
   */
  Cursor: { input: string; output: string };
  /**
   * YYYY-MM-DD
   * Example: 2021-02-04
   */
  Date: { input: string; output: string };
  /**
   * YYYY-MM-DDTHH:mm:ssZ
   * Example: 2021-02-04T12:17:51Z
   */
  DateTime: { input: string; output: string };
  /**
   * Integer value from 0 to 100
   * Example: 77
   */
  Percent: { input: string; output: string };
};

export type ActivateCompanyInput = {
  id: Scalars['ID']['input'];
};

export type ActivateCompanyPayload = {
  __typename?: 'ActivateCompanyPayload';
  company: Company;
};

export type ActivatePartnerInput = {
  id: Scalars['ID']['input'];
};

export type ActivatePartnerPayload = {
  __typename?: 'ActivatePartnerPayload';
  partner: Partner;
};

export type ApplicationToAddPartnersItem = {
  __typename?: 'ApplicationToAddPartnersItem';
  id: Scalars['ID']['output'];
  note?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type ApplicationToAddPartnersItemInput = {
  note?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export enum CommonPostbackStatus {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  NotConnected = 'NOT_CONNECTED',
  PartiallyDisconnected = 'PARTIALLY_DISCONNECTED',
}

export type Company = UserInterface & {
  __typename?: 'Company';
  companyPartners: CompanyPartnerConnection;
  contact: Contact;
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastApplication: CompanyApplication;
  mediaCampaigns: MediaCampaignConnection;
  mediaItem: MediaItemInterface;
  mediaItems: MediaItemConnection;
  name: Scalars['String']['output'];
  nextRewardInfo: NextRewardInfo;
  passwordUpdatedAt: Scalars['DateTime']['output'];
  status: CompanyStatus;
  tariff: TariffInterface;
  tariffs: Array<TariffInterface>;
  tariffsForMediaItemCreation: Array<TariffInterface>;
  website?: Maybe<Scalars['String']['output']>;
};

export type CompanyCompanyPartnersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

export type CompanyMediaCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyMediaItemArgs = {
  id: Scalars['ID']['input'];
};

export type CompanyMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type CompanyTariffArgs = {
  id: Scalars['ID']['input'];
};

export type CompanyApplication = {
  __typename?: 'CompanyApplication';
  contact: Contact;
  declineReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  status: CompanyApplicationStatus;
  trafficType?: Maybe<Scalars['String']['output']>;
};

export enum CompanyApplicationStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type CompanyContactInput = {
  type: ContactType;
  value: Scalars['String']['input'];
};

export type CompanyPartner = {
  __typename?: 'CompanyPartner';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  mediaItemsCount: Scalars['Int']['output'];
  note?: Maybe<Scalars['String']['output']>;
  username: Scalars['String']['output'];
};

export type CompanyPartnerConnection = {
  __typename?: 'CompanyPartnerConnection';
  edges: Array<CompanyPartnerConnectionEdge>;
  pageInfo: PageInfo;
};

export type CompanyPartnerConnectionEdge = {
  __typename?: 'CompanyPartnerConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: CompanyPartner;
};

export enum CompanyStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export type Contact = {
  __typename?: 'Contact';
  type: ContactType;
  value: Scalars['String']['output'];
};

export enum ContactType {
  Other = 'OTHER',
  Skype = 'SKYPE',
  Telegram = 'TELEGRAM',
  Whatsapp = 'WHATSAPP',
}

export type CpaTariff = TariffInterface & {
  __typename?: 'CpaTariff';
  baseline: MoneyAmount;
  createdAt: Scalars['DateTime']['output'];
  geolocation: Array<Scalars['CountryCode']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  reward: MoneyAmount;
};

export type CreateApplicationToAddPartnersInput = {
  applicationToAddPartnersItems: Array<ApplicationToAddPartnersItemInput>;
  companyId: Scalars['ID']['input'];
};

export type CreateApplicationToAddPartnersPayload = {
  __typename?: 'CreateApplicationToAddPartnersPayload';
  applicationToAddPartnersItems: Array<ApplicationToAddPartnersItem>;
};

export type CreateCompanyReferralLinkMediaItemInput = {
  companyId: Scalars['ID']['input'];
  companyPartnerId?: InputMaybe<Scalars['ID']['input']>;
  mediaCampaignId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  tariffId: Scalars['ID']['input'];
};

export type CreateCompanyReferralLinkMediaItemPayload = {
  __typename?: 'CreateCompanyReferralLinkMediaItemPayload';
  referralLinkMediaItem: ReferralLinkMediaItem;
};

export type CreatePartnerReferralLinkMediaItemInput = {
  mediaCampaignId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  partnerId: Scalars['ID']['input'];
  tariffId: Scalars['ID']['input'];
};

export type CreatePartnerReferralLinkMediaItemPayload = {
  __typename?: 'CreatePartnerReferralLinkMediaItemPayload';
  referralLinkMediaItem: ReferralLinkMediaItem;
};

export type CreatePostbackInput = {
  event: PostbackEvent;
  mediaItemId: Scalars['ID']['input'];
  method: PostbackMethod;
  url: Scalars['String']['input'];
};

export type CreatePostbackPayload = {
  __typename?: 'CreatePostbackPayload';
  postback: Postback;
};

export type CurrencyAccount = {
  __typename?: 'CurrencyAccount';
  amount?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isCurrent: Scalars['Boolean']['output'];
};

export type DateRange = {
  __typename?: 'DateRange';
  end: Scalars['Date']['output'];
  start: Scalars['Date']['output'];
};

export type DeletePostbackInput = {
  id: Scalars['ID']['input'];
};

export type DeletePostbackPayload = {
  __typename?: 'DeletePostbackPayload';
  id: Scalars['ID']['output'];
};

export type MediaCampaign = {
  __typename?: 'MediaCampaign';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type MediaCampaignConnection = {
  __typename?: 'MediaCampaignConnection';
  edges: Array<MediaCampaignEdge>;
  pageInfo: PageInfo;
};

export type MediaCampaignEdge = {
  __typename?: 'MediaCampaignEdge';
  cursor: Scalars['Cursor']['output'];
  node: MediaCampaign;
};

export type MediaItemConnection = {
  __typename?: 'MediaItemConnection';
  edges: Array<MediaItemConnectionEdge>;
  pageInfo: PageInfo;
};

export type MediaItemConnectionEdge = {
  __typename?: 'MediaItemConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: MediaItemInterface;
};

export type MediaItemInterface = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: MediaItemOwner;
  tariff: TariffInterface;
};

export type MediaItemOwner = Company | CompanyPartner | Partner;

export type MoneyAmount = {
  __typename?: 'MoneyAmount';
  currency: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateCompany: ActivateCompanyPayload;
  activatePartner: ActivatePartnerPayload;
  createApplicationToAddPartners: CreateApplicationToAddPartnersPayload;
  createCompanyReferralLinkMediaItem: CreateCompanyReferralLinkMediaItemPayload;
  createPartnerReferralLinkMediaItem: CreatePartnerReferralLinkMediaItemPayload;
  createPostback: CreatePostbackPayload;
  deletePostback: DeletePostbackPayload;
  reapplyCompanyApplication: ReapplyCompanyApplicationPayload;
  reapplyPartnerApplication: ReapplyPartnerApplicationPayload;
  registerCompany: RegisterCompanyPayload;
  registerPartner: RegisterPartnerPayload;
  resetUserPassword: ResetUserPasswordPayload;
  switchCompanyNextRewardCurrency: SwitchCompanyNextRewardCurrencyPayload;
  switchPartnerCurrentCurrencyAccount: SwitchPartnerCurrentCurrencyAccountPayload;
  switchPartnerNextRewardCurrency: SwitchPartnerNextRewardCurrencyPayload;
  updateCompanyIdentities: UpdateCompanyIdentitiesPayload;
  updatePartnerIdentities: UpdatePartnerIdentitiesPayload;
  updatePostback: UpdatePostbackPayload;
};

export type MutationActivateCompanyArgs = {
  input: ActivateCompanyInput;
};

export type MutationActivatePartnerArgs = {
  input: ActivatePartnerInput;
};

export type MutationCreateApplicationToAddPartnersArgs = {
  input: CreateApplicationToAddPartnersInput;
};

export type MutationCreateCompanyReferralLinkMediaItemArgs = {
  input: CreateCompanyReferralLinkMediaItemInput;
};

export type MutationCreatePartnerReferralLinkMediaItemArgs = {
  input: CreatePartnerReferralLinkMediaItemInput;
};

export type MutationCreatePostbackArgs = {
  input: CreatePostbackInput;
};

export type MutationDeletePostbackArgs = {
  input: DeletePostbackInput;
};

export type MutationReapplyCompanyApplicationArgs = {
  input: ReapplyCompanyApplicationInput;
};

export type MutationReapplyPartnerApplicationArgs = {
  input: ReapplyPartnerApplicationInput;
};

export type MutationRegisterCompanyArgs = {
  input: RegisterCompanyInput;
};

export type MutationRegisterPartnerArgs = {
  input: RegisterPartnerInput;
};

export type MutationResetUserPasswordArgs = {
  input: ResetUserPasswordInput;
};

export type MutationSwitchCompanyNextRewardCurrencyArgs = {
  input: SwitchCompanyNextRewardCurrencyInput;
};

export type MutationSwitchPartnerCurrentCurrencyAccountArgs = {
  input: SwitchPartnerCurrentCurrencyAccountInput;
};

export type MutationSwitchPartnerNextRewardCurrencyArgs = {
  input: SwitchPartnerNextRewardCurrencyInput;
};

export type MutationUpdateCompanyIdentitiesArgs = {
  input: UpdateCompanyIdentitiesInput;
};

export type MutationUpdatePartnerIdentitiesArgs = {
  input: UpdatePartnerIdentitiesInput;
};

export type MutationUpdatePostbackArgs = {
  input: UpdatePostbackInput;
};

export type NextRewardInfo = {
  __typename?: 'NextRewardInfo';
  currency: Scalars['String']['output'];
  period: DateRange;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor: Scalars['Cursor']['output'];
};

export type Partner = UserInterface & {
  __typename?: 'Partner';
  availableCurrencyAccounts: Array<Maybe<CurrencyAccount>>;
  birthday?: Maybe<Scalars['Date']['output']>;
  contact: Contact;
  cpaStatistic: PartnerCpaStatistic;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastApplication: PartnerApplication;
  lastName?: Maybe<Scalars['String']['output']>;
  mediaCampaigns: MediaCampaignConnection;
  mediaItem: MediaItemInterface;
  mediaItems: MediaItemConnection;
  nextRewardInfo: NextRewardInfo;
  passwordUpdatedAt: Scalars['DateTime']['output'];
  revShareStatistic: PartnerRevShareStatistic;
  status: PartnerStatus;
  tariff: TariffInterface;
  tariffs: Array<TariffInterface>;
  tariffsForMediaItemCreation: Array<TariffInterface>;
  username: Scalars['String']['output'];
};

export type PartnerMediaCampaignsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnerMediaItemArgs = {
  id: Scalars['ID']['input'];
};

export type PartnerMediaItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnerTariffArgs = {
  id: Scalars['ID']['input'];
};

export type PartnerApplication = {
  __typename?: 'PartnerApplication';
  contact: Contact;
  declineReason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  number: Scalars['String']['output'];
  status: PartnerApplicationStatus;
  trafficType?: Maybe<Scalars['String']['output']>;
};

export enum PartnerApplicationStatus {
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Pending = 'PENDING',
}

export type PartnerContactInput = {
  type: ContactType;
  value: Scalars['String']['input'];
};

export type PartnerCpaStatistic = {
  __typename?: 'PartnerCpaStatistic';
  statisticItems: PartnerCpaStatisticItemsConnection;
  total: PartnerCpaStatisticInfo;
};

export type PartnerCpaStatisticStatisticItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnerCpaStatisticInfo = {
  __typename?: 'PartnerCpaStatisticInfo';
  approved: Scalars['Int']['output'];
  averageDeposit: MoneyAmount;
  conversionHostsToRegPercent: Scalars['Int']['output'];
  conversionRegToDepPercent: Scalars['Int']['output'];
  depositsAll: MoneyAmount;
  firstDeposits: Scalars['Int']['output'];
  hits: Scalars['Int']['output'];
  hold: Scalars['Int']['output'];
  hosts: Scalars['Int']['output'];
  numberOfRedeposits: Scalars['Int']['output'];
  paid: Scalars['Int']['output'];
  qualified: Scalars['Int']['output'];
  redepositsAll: MoneyAmount;
  registrations: Scalars['Int']['output'];
  rejected: Scalars['Int']['output'];
  reward: MoneyAmount;
  withdrawalsAll: MoneyAmount;
};

export type PartnerCpaStatisticItem = {
  __typename?: 'PartnerCpaStatisticItem';
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  statisticInfo: PartnerCpaStatisticInfo;
};

export type PartnerCpaStatisticItemsConnection = {
  __typename?: 'PartnerCpaStatisticItemsConnection';
  edges: Array<PartnerCpaStatisticItemsConnectionEdge>;
  pageInfo: PageInfo;
};

export type PartnerCpaStatisticItemsConnectionEdge = {
  __typename?: 'PartnerCpaStatisticItemsConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: PartnerCpaStatisticItem;
};

export type PartnerRevShareStatistic = {
  __typename?: 'PartnerRevShareStatistic';
  statisticItems: PartnerRevShareStatisticItemsConnection;
  total: PartnerRevShareStatisticInfo;
};

export type PartnerRevShareStatisticStatisticItemsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type PartnerRevShareStatisticInfo = {
  __typename?: 'PartnerRevShareStatisticInfo';
  adminFee: MoneyAmount;
  averageDeposit: MoneyAmount;
  conversionHostsToRegPercent: Scalars['Int']['output'];
  conversionRegToDepPercent: Scalars['Int']['output'];
  depositsAll: MoneyAmount;
  depositsFee: MoneyAmount;
  firstDeposits: Scalars['Int']['output'];
  hits: Scalars['Int']['output'];
  hosts: Scalars['Int']['output'];
  numberOfRedeposits: Scalars['Int']['output'];
  redepositsAll: MoneyAmount;
  registrations: Scalars['Int']['output'];
  reward: MoneyAmount;
  withdrawalsAll: MoneyAmount;
  withdrawalsFee: MoneyAmount;
};

export type PartnerRevShareStatisticItem = {
  __typename?: 'PartnerRevShareStatisticItem';
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  statisticInfo: PartnerRevShareStatisticInfo;
};

export type PartnerRevShareStatisticItemsConnection = {
  __typename?: 'PartnerRevShareStatisticItemsConnection';
  edges: Array<PartnerRevShareStatisticItemsConnectionEdge>;
  pageInfo: PageInfo;
};

export type PartnerRevShareStatisticItemsConnectionEdge = {
  __typename?: 'PartnerRevShareStatisticItemsConnectionEdge';
  cursor: Scalars['Cursor']['output'];
  node: PartnerRevShareStatisticItem;
};

export enum PartnerStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
}

export type Postback = {
  __typename?: 'Postback';
  event: PostbackEvent;
  id: Scalars['ID']['output'];
  isConnected: Scalars['Boolean']['output'];
  method: PostbackMethod;
  url: Scalars['String']['output'];
};

export enum PostbackEvent {
  FirstDeposit = 'FIRST_DEPOSIT',
  QualificationCheck = 'QUALIFICATION_CHECK',
  Registration = 'REGISTRATION',
  Reward = 'REWARD',
}

export type PostbackEvents = {
  __typename?: 'PostbackEvents';
  availableEvents: Array<PostbackEvent>;
  totalEventsCount: Scalars['Int']['output'];
};

export type PostbackInfo = {
  __typename?: 'PostbackInfo';
  commonPostbackStatus: CommonPostbackStatus;
  events: PostbackEvents;
  postbacks?: Maybe<Array<Postback>>;
};

export enum PostbackMacros {
  ClickId = 'CLICK_ID',
  Currency = 'CURRENCY',
  Reward = 'REWARD',
  Tid = 'TID',
}

export enum PostbackMethod {
  Get = 'GET',
  Post = 'POST',
}

export type Query = {
  __typename?: 'Query';
  postbackMacrosByPostbackEvent: Array<PostbackMacros>;
  user: UserInterface;
};

export type QueryPostbackMacrosByPostbackEventArgs = {
  postbackEvent: PostbackEvent;
};

export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};

export type ReapplyCompanyApplicationInput = {
  companyId: Scalars['ID']['input'];
  contact: CompanyContactInput;
  trafficType?: InputMaybe<Scalars['String']['input']>;
};

export type ReapplyCompanyApplicationPayload = {
  __typename?: 'ReapplyCompanyApplicationPayload';
  application: CompanyApplication;
};

export type ReapplyPartnerApplicationInput = {
  contact: PartnerContactInput;
  partnerId: Scalars['ID']['input'];
  trafficType?: InputMaybe<Scalars['String']['input']>;
};

export type ReapplyPartnerApplicationPayload = {
  __typename?: 'ReapplyPartnerApplicationPayload';
  application: PartnerApplication;
};

export type ReferralLinkMediaItem = MediaItemInterface & {
  __typename?: 'ReferralLinkMediaItem';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner: MediaItemOwner;
  postbackInfo: PostbackInfo;
  tariff: TariffInterface;
};

export type RegisterCompanyInput = {
  companyName: Scalars['String']['input'];
  contact: CompanyContactInput;
  currency: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  trafficType?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type RegisterCompanyPayload = {
  __typename?: 'RegisterCompanyPayload';
  company: Company;
};

export type RegisterPartnerInput = {
  contact: PartnerContactInput;
  currency: Scalars['String']['input'];
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  trafficType?: InputMaybe<Scalars['String']['input']>;
  username: Scalars['String']['input'];
};

export type RegisterPartnerPayload = {
  __typename?: 'RegisterPartnerPayload';
  partner: Partner;
};

export type ResetUserPasswordInput = {
  currentEmail: Scalars['String']['input'];
  setPasswordPath: Scalars['String']['input'];
};

export type ResetUserPasswordPayload = {
  __typename?: 'ResetUserPasswordPayload';
  isSuccessful: Scalars['Boolean']['output'];
};

export type RevShareTariff = TariffInterface & {
  __typename?: 'RevShareTariff';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rewardPercent: Scalars['Percent']['output'];
};

export type SwitchCompanyNextRewardCurrencyInput = {
  companyId: Scalars['ID']['input'];
  currency: Scalars['String']['input'];
};

export type SwitchCompanyNextRewardCurrencyPayload = {
  __typename?: 'SwitchCompanyNextRewardCurrencyPayload';
  company: Company;
};

export type SwitchPartnerCurrentCurrencyAccountInput = {
  currency: Scalars['String']['input'];
  partnerId: Scalars['ID']['input'];
};

export type SwitchPartnerCurrentCurrencyAccountPayload = {
  __typename?: 'SwitchPartnerCurrentCurrencyAccountPayload';
  partner: Partner;
};

export type SwitchPartnerNextRewardCurrencyInput = {
  currency: Scalars['String']['input'];
  partnerId: Scalars['ID']['input'];
};

export type SwitchPartnerNextRewardCurrencyPayload = {
  __typename?: 'SwitchPartnerNextRewardCurrencyPayload';
  partner: Partner;
};

export type TariffInterface = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type UpdateCompanyIdentitiesInput = {
  contact: CompanyContactInput;
  id: Scalars['ID']['input'];
  website?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyIdentitiesPayload = {
  __typename?: 'UpdateCompanyIdentitiesPayload';
  company: Company;
};

export type UpdatePartnerIdentitiesInput = {
  birthday?: InputMaybe<Scalars['Date']['input']>;
  contact: PartnerContactInput;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePartnerIdentitiesPayload = {
  __typename?: 'UpdatePartnerIdentitiesPayload';
  partner: Partner;
};

export type UpdatePostbackInput = {
  method: PostbackMethod;
  postbackId: Scalars['ID']['input'];
  url: Scalars['String']['input'];
};

export type UpdatePostbackPayload = {
  __typename?: 'UpdatePostbackPayload';
  postback: Postback;
};

export type UserInterface = {
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export const ListAllOperations = {
  Query: {
    GetCompanyPartners: 'GetCompanyPartners',
    GetCompanyPartnersForMedia: 'GetCompanyPartnersForMedia',
    GetMediaCampaignsForMedia: 'GetMediaCampaignsForMedia',
    GetTariffsForMediaItemCreation: 'GetTariffsForMediaItemCreation',
    GetMacrosByEvent: 'GetMacrosByEvent',
    GetPostbackInfo: 'GetPostbackInfo',
    GetMediaItem: 'GetMediaItem',
    GetMediaItems: 'GetMediaItems',
    GetAvailableCurrencyAccounts: 'GetAvailableCurrencyAccounts',
    GetNextRewardInfo: 'GetNextRewardInfo',
    GetUserIdentities: 'GetUserIdentities',
    GetPartnerCpaStatisticItems: 'GetPartnerCpaStatisticItems',
    GetPartnerCpaStatisticTotal: 'GetPartnerCpaStatisticTotal',
    GetPartnerRevShareStatisticItems: 'GetPartnerRevShareStatisticItems',
    GetPartnerRevShareStatisticTotal: 'GetPartnerRevShareStatisticTotal',
    GetBasicTariffs: 'GetBasicTariffs',
    GetTariff: 'GetTariff',
    GetCompanyName: 'GetCompanyName',
    GetUserLastApplication: 'GetUserLastApplication',
    GetUserStatus: 'GetUserStatus',
    GetUserTypeName: 'GetUserTypeName',
  },
  Mutation: {
    ActivateCompany: 'ActivateCompany',
    ActivatePartner: 'ActivatePartner',
    CreateApplicationToAddPartners: 'CreateApplicationToAddPartners',
    CreateCompanyReferralLinkMediaItem: 'CreateCompanyReferralLinkMediaItem',
    CreatePartnerReferralLinkMediaItem: 'CreatePartnerReferralLinkMediaItem',
    CreatePostback: 'CreatePostback',
    DeletePostback: 'DeletePostback',
    UpdatePostback: 'UpdatePostback',
    ResetUserPassword: 'ResetUserPassword',
    SwitchPartnerCurrentCurrencyAccount: 'SwitchPartnerCurrentCurrencyAccount',
    SwitchCompanyNextRewardCurrency: 'SwitchCompanyNextRewardCurrency',
    SwitchPartnerNextRewardCurrency: 'SwitchPartnerNextRewardCurrency',
    UpdateCompanyIdentities: 'UpdateCompanyIdentities',
    UpdatePartnerIdentities: 'UpdatePartnerIdentities',
    ReapplyCompanyApplication: 'ReapplyCompanyApplication',
    ReapplyPartnerApplication: 'ReapplyPartnerApplication',
    RegisterCompany: 'RegisterCompany',
    RegisterPartner: 'RegisterPartner',
  },
  Fragment: {
    CompanyPartnersData: 'CompanyPartnersData',
    MediaCampaignConnectionData: 'MediaCampaignConnectionData',
    PostbackInfoData: 'PostbackInfoData',
    MediaItemBasicData: 'MediaItemBasicData',
    MediaItemConnectionData: 'MediaItemConnectionData',
    MediaItemData: 'MediaItemData',
    TariffForMediaBasicData: 'TariffForMediaBasicData',
    TariffForMediaData: 'TariffForMediaData',
    AvailableCurrencyAccountsData: 'AvailableCurrencyAccountsData',
    PartnerCpaStatisticInfoData: 'PartnerCpaStatisticInfoData',
    PartnerCpaStatisticItemData: 'PartnerCpaStatisticItemData',
    PartnerRevShareStatisticInfoData: 'PartnerRevShareStatisticInfoData',
    PartnerRevShareStatisticItemData: 'PartnerRevShareStatisticItemData',
    TariffBasicData: 'TariffBasicData',
    TariffData: 'TariffData',
    MoneyAmountData: 'MoneyAmountData',
    PageInfoData: 'PageInfoData',
  },
};
