import { FC } from 'react';
import { KeyValueGrid } from 'ui/KeyValueGrid';
import { TariffForMediaData } from '../../../../queries/fragments/generated/TariffForMediaData';
import { getTariffKeyValueGridRowsByType } from './helpers';

interface MediaTariffProps {
  tariff: TariffForMediaData;
}

export const MediaTariff: FC<MediaTariffProps> = ({ tariff }) => (
  <KeyValueGrid
    data={getTariffKeyValueGridRowsByType(tariff)}
    shouldStackOnMd
  />
);
