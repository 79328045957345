import * as SchemaTypes from '../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DeletePostbackVariables = SchemaTypes.Exact<{
  input: SchemaTypes.DeletePostbackInput;
}>;

export type DeletePostback = {
  __typename: 'Mutation';
  deletePostback: { __typename: 'DeletePostbackPayload'; id: string };
};

export const DeletePostbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'DeletePostback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'DeletePostbackInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'deletePostback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type DeletePostbackMutationFn = Apollo.MutationFunction<
  DeletePostback,
  DeletePostbackVariables
>;

/**
 * __useDeletePostback__
 *
 * To run a mutation, you first call `useDeletePostback` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePostback` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePostback, { data, loading, error }] = useDeletePostback({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePostback(
  baseOptions?: Apollo.MutationHookOptions<
    DeletePostback,
    DeletePostbackVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePostback, DeletePostbackVariables>(
    DeletePostbackDocument,
    options
  );
}
export type DeletePostbackHookResult = ReturnType<typeof useDeletePostback>;
export type DeletePostbackMutationResult =
  Apollo.MutationResult<DeletePostback>;
export type DeletePostbackMutationOptions = Apollo.BaseMutationOptions<
  DeletePostback,
  DeletePostbackVariables
>;
