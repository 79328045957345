import * as SchemaTypes from '../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import { TariffBasicData } from './TariffBasicData';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetBasicTariffsVariables = SchemaTypes.Exact<{
  userId: SchemaTypes.Scalars['ID']['input'];
}>;

export type GetBasicTariffs = {
  __typename: 'Query';
  user:
    | {
        __typename: 'Company';
        id: string;
        tariffs: Array<
          | {
              __typename: 'CpaTariff';
              id: string;
              name: string;
              reward: {
                __typename: 'MoneyAmount';
                currency: string;
                value: number;
              };
            }
          | {
              __typename: 'RevShareTariff';
              rewardPercent: string;
              id: string;
              name: string;
            }
        >;
      }
    | {
        __typename: 'Partner';
        id: string;
        tariffs: Array<
          | {
              __typename: 'CpaTariff';
              id: string;
              name: string;
              reward: {
                __typename: 'MoneyAmount';
                currency: string;
                value: number;
              };
            }
          | {
              __typename: 'RevShareTariff';
              rewardPercent: string;
              id: string;
              name: string;
            }
        >;
      };
};

export const GetBasicTariffsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetBasicTariffs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'user' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'id' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Partner' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tariffs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'TariffBasicData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'Company' },
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'tariffs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'TariffBasicData' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...TariffBasicData.definitions,
  ],
} as unknown as DocumentNode;

/**
 * __useGetBasicTariffs__
 *
 * To run a query within a React component, call `useGetBasicTariffs` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicTariffs` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicTariffs({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetBasicTariffs(
  baseOptions: Apollo.QueryHookOptions<
    GetBasicTariffs,
    GetBasicTariffsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBasicTariffs, GetBasicTariffsVariables>(
    GetBasicTariffsDocument,
    options
  );
}
export function useGetBasicTariffsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBasicTariffs,
    GetBasicTariffsVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBasicTariffs, GetBasicTariffsVariables>(
    GetBasicTariffsDocument,
    options
  );
}
export type GetBasicTariffsHookResult = ReturnType<typeof useGetBasicTariffs>;
export type GetBasicTariffsLazyQueryHookResult = ReturnType<
  typeof useGetBasicTariffsLazyQuery
>;
export type GetBasicTariffsQueryResult = Apollo.QueryResult<
  GetBasicTariffs,
  GetBasicTariffsVariables
>;
