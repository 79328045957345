import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentModel } from 'components/PaymentModel';
import { TariffTypeName } from 'types';
import {
  KeyValueGrid,
  KeyValueGridRow,
  KeyValueGridSize,
} from 'ui/KeyValueGrid';
import { TariffBasicData } from '../../../../queries/generated/TariffBasicData';
import { getRewardByTariff } from './helpers/getRewardByTariff';

interface TariffCardBodyProps {
  tariff: TariffBasicData;
  className?: string;
}

export const TariffCardBody: FC<TariffCardBodyProps> = ({
  tariff,
  className,
}) => {
  const { t } = useTranslation('tariffs');

  const data: Array<KeyValueGridRow> = [
    {
      title: t('paymentModel'),
      value: <PaymentModel tariffTypeName={tariff.__typename} />,
    },
    {
      title:
        tariff.__typename === TariffTypeName.RevShareTariff
          ? t('rewardPercent')
          : t('reward'),
      value: getRewardByTariff(tariff) || '',
    },
  ];

  return (
    <KeyValueGrid
      className={className}
      data={data}
      equalColumnsWidth
      size={KeyValueGridSize.Small}
    />
  );
};
