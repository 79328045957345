import { HTMLAttributes } from 'react';
import classNames from 'classnames';
import type { IconComponent } from 'types';
import { IconSize } from './const';
import styles from './Icon.module.scss';
import { mapIconSizeToClass } from './helpers';

interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  iconComponent: IconComponent;
  spin?: boolean;
  containerClassName?: string;
  iconClassName?: string;
  size?: IconSize;
  hasDefaultColor?: boolean;
}

export const Icon: React.FC<IconProps> = ({
  iconComponent: SVGComponent,
  containerClassName,
  iconClassName,
  spin = false,
  size = IconSize.Large,
  hasDefaultColor = true,
  ...spanProps
}) => (
  <span
    data-testid="iconContainer"
    className={classNames(styles.iconContainer, containerClassName)}
    {...spanProps}
  >
    <SVGComponent
      data-testid="icon"
      className={classNames(
        styles.icon,
        iconClassName,
        spin && styles.spin,
        mapIconSizeToClass[size],
        hasDefaultColor && styles.hasDefaultColor
      )}
      role="img"
    />
  </span>
);
