import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SPEC_HEADER_LARGE } from 'const';
import { CopyText } from 'ui/CopyText';
import { SupportButtons } from 'components/SupportButtons';
import styles from './PendingBlock.module.scss';

interface PendingBlockProps {
  applicationNumber: string;
}

export const PendingBlock: FC<PendingBlockProps> = ({ applicationNumber }) => {
  const { t } = useTranslation('auth');

  return (
    <div data-testid="pending-block">
      <div className={styles.contentContainer}>
        <h1 className={SPEC_HEADER_LARGE}>{t('application.pendingHeader')}</h1>
        <div className={styles.descriptionBlock}>
          <CopyText tooltipText={t('application.numberCopied')}>
            {applicationNumber}
          </CopyText>
          {t('application.pendingDescription')}
        </div>
      </div>
      <SupportButtons />
    </div>
  );
};
