import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useModalContext,
  updateConfirmClose,
  closeModal as closeModalAction,
} from 'ui/Modal';
import { useConfirmModalContext } from 'ui/ConfirmModal';
import { Button, ButtonSize } from 'ui/Button';
import { checkIsCurrency } from 'utils/currency/checkIsCurrency';
import { Form, Formik, FormikValues } from 'formik';
import { useGetUserTypeNameQuery } from 'hooks/queryHooks/useGetUserTypeNameQuery';
import { NextRewardInfo } from 'types/generated/gql';
import { useSwitchNextRewardCurrency } from './hooks/useSwitchNextRewardCurrency';
import { FieldName } from './const';
import { NextRewardCurrencySelect } from './components/NextRewardCurrencySelect';
import styles from './NextRewardCurrencyModal.module.scss';

interface NextRewardCurrencyModalProps {
  nextRewardInfo: NextRewardInfo;
}

export const NextRewardCurrencyModal = ({
  nextRewardInfo,
}: NextRewardCurrencyModalProps) => {
  const { t } = useTranslation('payments');

  const { dispatch } = useModalContext();
  const { showConfirmation } = useConfirmModalContext();

  const { userTypeName } = useGetUserTypeNameQuery();

  const initialValues = {
    [FieldName.Currency]: nextRewardInfo.currency,
  };

  const closeModal = useCallback(() => {
    dispatch(closeModalAction());
  }, [dispatch]);

  const handleCompleted = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const { switchNextRewardCurrency } = useSwitchNextRewardCurrency({
    onCompleted: handleCompleted,
    userTypeName,
  });

  const handleDirtyChange = useCallback(
    (dirty: boolean) => {
      dispatch(
        updateConfirmClose({
          confirmClose: dirty ? showConfirmation : null,
        })
      );
    },
    [dispatch, showConfirmation]
  );

  const handleSubmit = (values: FormikValues) => {
    if (checkIsCurrency(values.currency)) {
      switchNextRewardCurrency(values.currency);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ dirty }) => (
        <Form>
          <p className={styles.changeNextRewardDeadlineText}>
            {t('nextRewardCurrencyModal.changeNextRewardDeadline')}
          </p>
          <NextRewardCurrencySelect onDirtyChange={handleDirtyChange} />
          <Button type="submit" size={ButtonSize.Large} disabled={!dirty}>
            {t('nextRewardCurrencyModal.changeNextRewardCurrencyButton')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
