import { useTranslation } from 'react-i18next';
import { Select } from 'ui/formItems';
import { currencySelectOptions } from 'utils/currency/currencySelectOptions';
import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FieldName } from '../../const';
import styles from './NextRewardCurrencySelect.module.scss';

interface NextRewardCurrencySelectProps {
  onDirtyChange: (dirty: boolean) => void;
}

export const NextRewardCurrencySelect = ({
  onDirtyChange,
}: NextRewardCurrencySelectProps) => {
  const { t } = useTranslation('payments');

  const { dirty } = useFormikContext();

  useEffect(() => {
    onDirtyChange(dirty);
  }, [dirty, onDirtyChange]);

  return (
    <Select
      wrapperClassName={styles.select}
      label={t('nextRewardCurrencyModal.currency')}
      name={FieldName.Currency}
      options={currencySelectOptions}
    />
  );
};
