import * as SchemaTypes from '../../../../../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetMacrosByEventVariables = SchemaTypes.Exact<{
  postbackEvent: SchemaTypes.PostbackEvent;
}>;

export type GetMacrosByEvent = {
  __typename: 'Query';
  postbackMacrosByPostbackEvent: Array<SchemaTypes.PostbackMacros>;
};

export const GetMacrosByEventDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetMacrosByEvent' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'postbackEvent' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'PostbackEvent' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'postbackMacrosByPostbackEvent' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'postbackEvent' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'postbackEvent' },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

/**
 * __useGetMacrosByEvent__
 *
 * To run a query within a React component, call `useGetMacrosByEvent` and pass it any options that fit your needs.
 * When your component renders, `useGetMacrosByEvent` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMacrosByEvent({
 *   variables: {
 *      postbackEvent: // value for 'postbackEvent'
 *   },
 * });
 */
export function useGetMacrosByEvent(
  baseOptions: Apollo.QueryHookOptions<
    GetMacrosByEvent,
    GetMacrosByEventVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMacrosByEvent, GetMacrosByEventVariables>(
    GetMacrosByEventDocument,
    options
  );
}
export function useGetMacrosByEventLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetMacrosByEvent,
    GetMacrosByEventVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMacrosByEvent, GetMacrosByEventVariables>(
    GetMacrosByEventDocument,
    options
  );
}
export type GetMacrosByEventHookResult = ReturnType<typeof useGetMacrosByEvent>;
export type GetMacrosByEventLazyQueryHookResult = ReturnType<
  typeof useGetMacrosByEventLazyQuery
>;
export type GetMacrosByEventQueryResult = Apollo.QueryResult<
  GetMacrosByEvent,
  GetMacrosByEventVariables
>;
