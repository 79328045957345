import { useCallback } from 'react';
import { ListAllOperations } from 'types/generated/gql';
import { FormikState } from 'formik';
import { toast } from 'ui/toast';
import { PostbackValues } from '../../../types';
import { useUpdatePostback } from '../../../queries/generated/UpdatePostback';
import { FieldName } from '../const';

interface UpdatePostbackParams {
  values: Omit<PostbackValues, FieldName.Event> & {
    postbackId: string;
  };
  resetForm: (nextState?: Partial<FormikState<PostbackValues>>) => void;
}
export const useUpdatePostbackMutation = () => {
  const [updatePostbackMutation] = useUpdatePostback();

  const updatePostback = useCallback(
    ({
      values: { method, url, postbackId },
      resetForm,
    }: UpdatePostbackParams) => {
      if (!method || !url) {
        return undefined;
      }

      return updatePostbackMutation({
        variables: {
          input: {
            postbackId,
            method,
            url,
          },
        },
        onError: (error) => {
          toast.error({
            text: error.message,
            header: 'Error',
          });
        },
        onCompleted: (data) => {
          const { postback } = data.updatePostback;

          resetForm({
            values: {
              event: postback.event,
              method: postback.method,
              url: postback.url,
            },
          });
        },
        refetchQueries: [ListAllOperations.Query.GetPostbackInfo],
        awaitRefetchQueries: true,
      });
    },
    [updatePostbackMutation]
  );

  return {
    updatePostback,
  };
};
