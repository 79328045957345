import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import commonRU from './locales/ru/common.json';
import authRU from './locales/ru/auth.json';
import companyPartnersRU from './locales/ru/companyPartners.json';
import mediaRU from './locales/ru/media.json';
import profileRU from './locales/ru/profile.json';
import statisticRU from './locales/ru/statistic.json';
import tariffsRU from './locales/ru/tariffs.json';
import notFoundRU from './locales/ru/notFound.json';
import paymentsRU from './locales/ru/payments.json';

import commonEN from './locales/en/common.json';

export const resources = {
  ru: {
    common: commonRU,
    auth: authRU,
    companyPartners: companyPartnersRU,
    media: mediaRU,
    profile: profileRU,
    statistic: statisticRU,
    tariffs: tariffsRU,
    notFound: notFoundRU,
    payments: paymentsRU,
  },
  en: {
    common: commonEN,
  },
};

export const defaultNS = 'common';

i18n.use(initReactI18next).init({
  resources,
  lng: 'ru',
  defaultNS,

  interpolation: {
    escapeValue: false,
  },
});

export const { t } = i18n;
export default i18n;
