import { Currency, UserTypeName } from 'types';
import { useSwitchPartnerCurrentCurrencyAccountMutation } from './useSwitchPartnerCurrentCurrencyAccountMutation';

interface UseSwitchNextRewardCurrencyParams {
  userTypeName?: 'Partner' | 'Company';
  onCompleted: (currency: Currency) => void;
}

export const useSwitchCurrentCurrencyAccount = ({
  userTypeName,
  onCompleted,
}: UseSwitchNextRewardCurrencyParams) => {
  const useSwitchPartnerCurrentCurrencyAccountByUserType =
    userTypeName === UserTypeName.Partner
      ? useSwitchPartnerCurrentCurrencyAccountMutation
      : // TODO: replace with mutation for a Company
        useSwitchPartnerCurrentCurrencyAccountMutation;

  return useSwitchPartnerCurrentCurrencyAccountByUserType(onCompleted);
};
