import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateCompanyIdentitiesVariables = SchemaTypes.Exact<{
  input: SchemaTypes.UpdateCompanyIdentitiesInput;
}>;

export type UpdateCompanyIdentities = {
  __typename: 'Mutation';
  updateCompanyIdentities: {
    __typename: 'UpdateCompanyIdentitiesPayload';
    company: {
      __typename: 'Company';
      id: string;
      website?: string | null;
      contact: {
        __typename: 'Contact';
        type: SchemaTypes.ContactType;
        value: string;
      };
    };
  };
};

export const UpdateCompanyIdentitiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'UpdateCompanyIdentities' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateCompanyIdentitiesInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'updateCompanyIdentities' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'company' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'contact' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'type' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'value' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'website' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type UpdateCompanyIdentitiesMutationFn = Apollo.MutationFunction<
  UpdateCompanyIdentities,
  UpdateCompanyIdentitiesVariables
>;

/**
 * __useUpdateCompanyIdentities__
 *
 * To run a mutation, you first call `useUpdateCompanyIdentities` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyIdentities` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyIdentities, { data, loading, error }] = useUpdateCompanyIdentities({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyIdentities(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyIdentities,
    UpdateCompanyIdentitiesVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCompanyIdentities,
    UpdateCompanyIdentitiesVariables
  >(UpdateCompanyIdentitiesDocument, options);
}
export type UpdateCompanyIdentitiesHookResult = ReturnType<
  typeof useUpdateCompanyIdentities
>;
export type UpdateCompanyIdentitiesMutationResult =
  Apollo.MutationResult<UpdateCompanyIdentities>;
export type UpdateCompanyIdentitiesMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyIdentities,
  UpdateCompanyIdentitiesVariables
>;
