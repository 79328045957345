import { FC } from 'react';
import { Form, Formik } from 'formik';
import {
  FormBlockMarginSize,
  FormBlockWrapper,
  SelectAndInput,
  Textarea,
} from 'ui/formItems';
import { useTranslation } from 'react-i18next';
import { contactTypeOption } from 'helpers';
import { Button } from 'ui/Button';
import { Contact } from 'types/generated/gql';
import { FieldName } from './const';
import { ApplicationTypeName, ReapplicationValues } from './types';
import { validateReapplication } from './validation';
import { useReapplyApplication } from './hooks/useReapplyApplication';

interface ReapplicationFormProps {
  applicationTypeName: ApplicationTypeName;
  contact: Contact;
  trafficType?: string | null;
}

export const ReapplicationForm: FC<ReapplicationFormProps> = ({
  applicationTypeName,
  contact,
  trafficType,
}) => {
  const { t } = useTranslation('auth');

  const { reapplyApplication, loading: reapplyLoading } =
    useReapplyApplication(applicationTypeName);

  const initialValues: ReapplicationValues = {
    [FieldName.ContactType]: contact.type,
    [FieldName.ContactValue]: contact.value,
    [FieldName.TrafficSource]: trafficType,
  };

  const handleSubmit = (values: ReapplicationValues) => {
    reapplyApplication({
      contactValue: values.contactValue,
      contactType: values.contactType,
      trafficSource: values.trafficSource,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      validate={validateReapplication}
    >
      {({ errors }) => (
        <Form data-testid="reapplication-form" noValidate>
          <FormBlockWrapper marginBottomSize={FormBlockMarginSize.Large}>
            <SelectAndInput
              selectProps={{
                name: FieldName.ContactType,
                label: t('registration.formCommonFields.contactType'),
                options: contactTypeOption,
              }}
              inputProps={{
                name: FieldName.ContactValue,
                label: t('registration.formCommonFields.contactText'),
              }}
              isError={Boolean(errors.contactValue)}
            />
            <Textarea
              formValidationProps={{ errorTooltipPlacement: 'right' }}
              name={FieldName.TrafficSource}
              label={t('registration.formCommonFields.trafficSource')}
            />
          </FormBlockWrapper>
          <Button type="submit" isLoading={reapplyLoading} isFullWidth>
            {t('reapplication.submitButton')}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
