import { useTranslation } from 'react-i18next';
import { useAuth } from 'components/auth';
import { toast } from 'ui/toast';
import { useGetUserIdentities } from '../queries/generated/GetUserIdentities';

export const useUserIdentities = () => {
  const { t } = useTranslation('profile');
  const { user } = useAuth();

  const { data, loading, error } = useGetUserIdentities({
    variables: {
      id: user.id,
    },
    onError: () => {
      toast.error({
        header: t('getProfileErrorTitle'),
        text: t('getProfileErrorText'),
        toastId: 'getProfile',
      });
    },
  });

  return {
    userIdentities: data?.user,
    loading,
    error,
  };
};
