import { t } from 'i18n';
import { Tab } from 'ui/Tabs';
import { MediaItemData } from '../../queries/fragments/generated/MediaItemData';
import { MediaDetail } from './components/MediaDetail';
import { MediaTariff } from './components/MediaTariff';
import { Postbacks } from './components/Postbacks';
import { EditTab, companyEditTypeArray, partnerEditTypeArray } from './const';

export const tabTypeToTab = (type: EditTab, mediaItem: MediaItemData) => {
  const map: Record<EditTab, Tab> = {
    [EditTab.Detail]: {
      title: t('media:aboutTab'),
      content: <MediaDetail mediaItem={mediaItem} />,
    },
    [EditTab.Tariff]: {
      title: t('media:tariffs.tariff'),
      content: <MediaTariff tariff={mediaItem.tariff} />,
    },
    [EditTab.Postbacks]: {
      title: t('media:postbacks'),
      content: <Postbacks mediaItemId={mediaItem.id} />,
    },
  };

  return map[type];
};

export const createTabs = (
  mediaItem: MediaItemData,
  isCompany: boolean
): Array<Tab> =>
  (isCompany ? companyEditTypeArray : partnerEditTypeArray).map((tab) =>
    tabTypeToTab(tab, mediaItem)
  );

export const getTabIndex = (tabType: EditTab, isCompany: boolean) =>
  (isCompany ? companyEditTypeArray : partnerEditTypeArray).indexOf(tabType);
