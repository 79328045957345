import { FC } from 'react';
import { Icon, IconSize } from 'ui/Icon';
import { ReactComponent as DocSearchSvg } from 'assets/icons/line/DocSearch.svg';
import styles from './EmptyDataBlock.module.scss';

interface EmptyDataBlockProps {
  emptyDataText: string;
  iconSize?: IconSize.ExtraLarge | IconSize.Large;
}

export const EmptyDataBlock: FC<EmptyDataBlockProps> = ({
  emptyDataText,
  iconSize = IconSize.ExtraLarge,
}) => (
  <div className={styles.container}>
    <div className={styles.emptyDataElement}>
      <Icon
        className={styles.image}
        iconComponent={DocSearchSvg}
        hasDefaultColor={false}
        size={iconSize}
      />
      <span className={styles.text}>{emptyDataText}</span>
    </div>
  </div>
);
