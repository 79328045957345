import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SPEC_HEADER_LARGE } from 'const';
import { TwoFactorGeneration } from '../TwoFactorGeneration';
import { TwoFactorSendForm } from '../TwoFactorSendForm';
import styles from './TwoFactorGenerationBlock.module.scss';

export const TwoFactorGenerationBlock: FC = () => {
  const { t } = useTranslation('auth');

  return (
    <>
      <h1 className={SPEC_HEADER_LARGE}>{t('twoFactorGeneration.header')}</h1>
      <div className={styles.textBlock}>
        <p>{t('twoFactorGeneration.descriptionOne')}</p>
        <p>{t('twoFactorGeneration.descriptionTwo')}</p>
      </div>
      <TwoFactorGeneration />
      <TwoFactorSendForm
        disabledButtonTooltip={t('twoFactorGeneration.disabledButtonTooltip')}
        inputLabel={t('twoFactorGeneration.inputLabel')}
        buttonText={t('twoFactorGeneration.buttonText')}
      />
    </>
  );
};
