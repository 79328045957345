import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useModalContext, openModal } from 'ui/Modal';
import {
  ConfirmModal,
  ConfirmModalContent,
  ConfirmModalContextProvider,
} from 'ui/ConfirmModal';
import { EditingIdsContextProvider } from 'contexts/EditingIdsContext';
import { EditMediaModal } from '../components/EditMediaModal';
import { CreateMediaModal } from '../components/CreateMediaModal';
import { EditTab } from '../components/EditMediaModal/const';

export const useOpenModal = () => {
  const { t } = useTranslation(['common', 'media']);
  const { dispatch } = useModalContext();

  const confirmModalContent: ConfirmModalContent = useMemo(
    () => ({
      cancelButton: t('pageCloseConfirmModalContent.cancelButton'),
      confirmButton: t('pageCloseConfirmModalContent.confirmButton'),
      text: t('pageCloseConfirmModalContent.text'),
      title: t('pageCloseConfirmModalContent.title'),
    }),
    [t]
  );

  const openCreateModal = useCallback(() => {
    dispatch(
      openModal({
        title: t('media:createMedia'),
        content: (
          <ConfirmModalContextProvider>
            <CreateMediaModal />
            <ConfirmModal content={confirmModalContent} />
          </ConfirmModalContextProvider>
        ),
      })
    );
  }, [confirmModalContent, dispatch, t]);

  const openEditModal = useCallback(
    (mediaItemId: string, defaultActiveTab?: EditTab) => {
      dispatch(
        openModal({
          title: t('media:editMediaModalTitle'),
          subTitle: `#${mediaItemId}`,
          content: (
            <EditingIdsContextProvider>
              <ConfirmModalContextProvider>
                <EditMediaModal
                  mediaItemId={mediaItemId}
                  defaultActiveTab={defaultActiveTab}
                />
                <ConfirmModal content={confirmModalContent} />
              </ConfirmModalContextProvider>
            </EditingIdsContextProvider>
          ),
        })
      );
    },
    [confirmModalContent, dispatch, t]
  );

  return { openCreateModal, openEditModal };
};
