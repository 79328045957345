import * as SchemaTypes from '../../../../../../../../../../types/generated/gql';

import * as CommonType from 'types';
import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SwitchPartnerNextRewardCurrencyVariables = SchemaTypes.Exact<{
  input: SchemaTypes.SwitchPartnerNextRewardCurrencyInput;
}>;

export type SwitchPartnerNextRewardCurrency = {
  __typename: 'Mutation';
  switchPartnerNextRewardCurrency: {
    __typename: 'SwitchPartnerNextRewardCurrencyPayload';
    partner: {
      __typename: 'Partner';
      id: string;
      nextRewardInfo: {
        __typename: 'NextRewardInfo';
        currency: string;
        period: { __typename: 'DateRange'; end: string; start: string };
      };
    };
  };
};

export const SwitchPartnerNextRewardCurrencyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'SwitchPartnerNextRewardCurrency' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'SwitchPartnerNextRewardCurrencyInput',
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'switchPartnerNextRewardCurrency' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'partner' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'nextRewardInfo' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currency' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'period' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'end' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'start' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export type SwitchPartnerNextRewardCurrencyMutationFn = Apollo.MutationFunction<
  SwitchPartnerNextRewardCurrency,
  SwitchPartnerNextRewardCurrencyVariables
>;

/**
 * __useSwitchPartnerNextRewardCurrency__
 *
 * To run a mutation, you first call `useSwitchPartnerNextRewardCurrency` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchPartnerNextRewardCurrency` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchPartnerNextRewardCurrency, { data, loading, error }] = useSwitchPartnerNextRewardCurrency({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSwitchPartnerNextRewardCurrency(
  baseOptions?: Apollo.MutationHookOptions<
    SwitchPartnerNextRewardCurrency,
    SwitchPartnerNextRewardCurrencyVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SwitchPartnerNextRewardCurrency,
    SwitchPartnerNextRewardCurrencyVariables
  >(SwitchPartnerNextRewardCurrencyDocument, options);
}
export type SwitchPartnerNextRewardCurrencyHookResult = ReturnType<
  typeof useSwitchPartnerNextRewardCurrency
>;
export type SwitchPartnerNextRewardCurrencyMutationResult =
  Apollo.MutationResult<SwitchPartnerNextRewardCurrency>;
export type SwitchPartnerNextRewardCurrencyMutationOptions =
  Apollo.BaseMutationOptions<
    SwitchPartnerNextRewardCurrency,
    SwitchPartnerNextRewardCurrencyVariables
  >;
